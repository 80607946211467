import React, { ChangeEvent, useState, useEffect, FormEvent, FocusEvent } from 'react';
import './Vendas.css';
import SideBar from '../Components/SideBar';
import ClienteVO from '../VO/ClienteVO';
import { BsSearch } from 'react-icons/bs';
import ItemVenda from '../VO/ItemVendaVO';
import { FaArrowLeft, FaPlus, FaTrash, FaTrashAlt } from 'react-icons/fa';
import ProdutoVO from '../VO/ProdutoVO';
import api from '../api';
import { Link, useHistory, useParams } from 'react-router-dom';
import VendaVO from '../VO/VendaVO';
import { converterMoeda, debounce } from '../Components/global';
import Loading from '../Components/Loading';
import AlertMessage from '../Components/AlertMessage';
import VariacaoVO from '../VO/VariacaoVO';
import { AiFillPrinter } from 'react-icons/ai';
import ItemVendaVO from '../VO/ItemVendaVO';
import PagamentoVO from '../VO/PagamentoVO';
import UsuarioVO from '../VO/UsuarioVO';

interface ParamTypes {
    id_venda: string
}

const CadastrarVendas: React.FC = () => {

    const [cliente, setCliente] = useState(new ClienteVO(0, '', '', '', '', '', 0, '', '', '', '', '', ''));
    const [venda, setVenda] = useState(new VendaVO(0, 0, 0, 0, 0, 1, 'Em produção', '', '', '', '', '', 0));
    const [pagamento, setPagamento] = useState(new PagamentoVO(0, 0, 0, '', ''));
    const [pagamentos, setPagamentos] = useState(Array<PagamentoVO>);
    const [clientes, setClientes] = useState(Array<ClienteVO>);
    const [itensVenda, setItensVenda] = useState(Array<ItemVenda>);
    const [todosProdutos, setTodosProdutos] = useState(Array<ProdutoVO>);
    const [todosDesigners, setTodosDesigners] = useState(Array<UsuarioVO>);
    const [_novaImagem, setNovaImagem] = useState('');
    const [loading, setLoading] = useState(true);
    const [_validar, setValidar] = useState(false);
    const [clienteValido, setClienteValido] = useState(false);
    const [itensValido, setItensValido] = useState(false);
    const [imgDefault, _setImgDefault] = useState('assets/default.png');
    const [alert, setAlert] = useState({
        show: false,
        type: '',
        title: '',
        mainMessage: '',
        message2: '',
    });
    const { id_venda } = useParams<ParamTypes>();
    const history = useHistory();

    async function listarProdutos() {
        await api.get('/ProdutoDAO.php?listarTodos=listarTodos')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTodosProdutos(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function listarTipoVariacao(id_produto: number, index: number) {
        await api.get('/TipoVariacaoDAO.php?listarPorProduto=' + id_produto)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setItensVenda(itensVenda.map((el, i) => {
                                let aux = JSON.parse(JSON.stringify(el));
                                if (index === i) {
                                    if (id_produto !== 0) {
                                        aux["fk_produto"] = id_produto;
                                    }
                                    else {
                                        aux["fk_produto"] = 0;
                                    }
                                    aux["tipos_variacoes"] = res.data;
                                }
                                return aux;
                            }));
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function handlerSelectItem(event: ChangeEvent<HTMLSelectElement>) {
        let index = parseInt(event.target.getAttribute("data-id") ?? '-1');
        if (event.target.name !== 'fk_produto') {
            await setItensVenda(itensVenda.map((el, i) => {
                let aux = JSON.parse(JSON.stringify(el));
                if (index === i) {
                    aux[event.target.name] = event.target.value;
                }
                return aux;
            }));
        }
        else {
            listarTipoVariacao(parseInt(event.target.value), index);
        }
        validarItens()
    }

    function handlerSelectItemVariacao(event: ChangeEvent<HTMLSelectElement>) {
        let item = parseInt(event.target.getAttribute("data-id-item") ?? '-1');
        let variacao = parseInt(event.target.getAttribute("data-id-item-variacao") ?? '-1');
        setItensVenda(itensVenda.map((it, i) => {
            let aux = JSON.parse(JSON.stringify(it));
            if (item === i) {
                it.tipos_variacoes?.map((_va, j) => {
                    if (variacao === j) {
                        aux['tipos_variacoes'][j]['variacao_selecionada'] = aux['tipos_variacoes'][j]["variacoes"].filter((el: VariacaoVO) => el.id_variacao.toString() === event.target.value)[0];

                    }
                })
            }
            return aux;
        }));
        validarItens()
    }

    function handlerInputItem(event: ChangeEvent<HTMLInputElement>) {
        let soma = 0;
        let aux2: Array<ItemVenda> = [];
        setItensVenda(itensVenda.map((el, i) => {
            let aux = JSON.parse(JSON.stringify(el));
            if (parseInt(event.target.getAttribute("data-id") ?? '-1') === i) {
                if (event.target.files && event.target.files[0]) {
                    var maxSize = 1048576;
                    if (event.target.files[0].size < maxSize) {
                        var reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = function () {
                            aux["img_item"] = reader.result?.toString();
                            setNovaImagem(reader.result ? reader.result.toString() : '');
                        };
                        reader.onerror = function (error) {
                            console.log('Error: ', error);
                        };
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "O tamanho do arquivo excede o limite permitido de " + (maxSize / 1000000).toFixed(0) + "Mb",
                            message2: '',
                        });
                    }

                }
                else {
                    if (event.target.name === 'valor_unitario') {
                        aux['total_item'] = event.target.value ? (
                            parseFloat(event.target.value) * (aux['quantidade'] ? parseInt(aux['quantidade']) : 0)
                        ) : '';
                        aux[event.target.name] = event.target.value ? (event.target.value) : '';
                    }
                    else if (event.target.name === 'quantidade') {
                        aux['total_item'] = aux['valor_unitario'] ? (
                            parseFloat(aux['valor_unitario']) * (event.target.value ? parseInt(event.target.value) : 0)
                        ) : '';
                        aux[event.target.name] = !isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : '';
                    }
                    else {
                        aux[event.target.name] = event.target.value;
                    }
                }
            }
            if (event.target.name === 'valor_unitario' || event.target.name === 'quantidade') {
                soma = soma + parseFloat(!isNaN(aux['total_item']) ? aux['total_item'] : 0);
                setVenda({ ...venda, total_venda: !isNaN(soma) ? soma : 0.00 });
            }
            aux2.push(aux);
            return aux;
        }));
        validarItens(aux2);
    }


    const handlerInputDebounce = debounce(async (event: any) => {
        if (event.target.value !== '' && event.target.value.length >= 4) {
            await api.get('/ClienteDAO.php?getByCod=' + event.target.value)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data && !res.data.error) {
                            setClientes(res.data);
                        }
                    }
                }).catch(console.error).finally(() => setLoading(false));
        }
        else {
            if (cliente.id_cliente !== 0) {
                setCliente(new ClienteVO(0, '', '', '', '', '', 0, '', '', '', '', '', ''));
                setVenda({ ...venda, fk_cliente: 0 })
            }
            setClientes([]);
        }
    }, 1000);

    async function handlerInput(event: ChangeEvent<HTMLInputElement>) {
        let aux = JSON.parse(JSON.stringify(cliente));
        if (event.target.name === 'cpf') {
            let value = event.target.value.replace(/\D/g, '');
            if (value.length <= 14) {
                if (value.length === 11) {
                    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
                } else if (value.length === 14) {
                    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
                }
                aux[event.target.name] = value;
            }
        }
        else {
            aux[event.target.name] = event.target.value;
        }
        setCliente(aux);
    }

    async function handlerInputVenda(event: ChangeEvent<HTMLInputElement>) {
        let aux = JSON.parse(JSON.stringify(venda));
        if (event.target.name == 'prioridade') {
            aux[event.target.name] = (event.target.value == '1' ? 0 : 1 );
        } else {
            aux[event.target.name] = event.target.value;
        }
        setVenda(aux);
    }
    async function handlerSelectVenda(event: ChangeEvent<HTMLSelectElement>) {
        let aux = JSON.parse(JSON.stringify(venda));
        aux[event.target.name] = event.target.value;
        setVenda(aux);
    }
    async function handlerInputPagameneto(event: ChangeEvent<HTMLInputElement>) {
        let aux = JSON.parse(JSON.stringify(pagamento));
        aux[event.target.name] = event.target.value;
        setPagamento(aux);
    }

    function validarCliente(c?: ClienteVO) {
        let nome = document.getElementById('nome_cliente') as HTMLInputElement
        let cpf = document.getElementById('cpf') as HTMLInputElement
        setClienteValido((c ? (c.nome_cliente !== '' && c.cpf !== '') : (nome.validationMessage === '' && cpf.validationMessage === '')));
    }

    function validarItens(aux?: Array<ItemVenda>) {
        let itens = aux ? aux : itensVenda;
        let count = itens.length;
        itens?.forEach((itemVenda) => {
            if (!isNaN(parseFloat(itemVenda.total_item + '')) &&
                !isNaN(parseInt(itemVenda.quantidade + '')) &&
                itemVenda.fk_produto !== 0) count--;
        })
        setItensValido((count === 0 && itens.length !== 0))
    }

    async function handlerValidationInput(event: FocusEvent<HTMLInputElement>) {
        event.target.className = 'form-control ' + (event.target.validationMessage !== '' ? 'is-invalid' : 'is-valid')
        validarCliente();
    }

    function inserirItemVenda() {
        setItensVenda([...itensVenda ?? [], new ItemVenda(0, 0, 0, 0, 0, 0, '', [], [])]);
        setItensValido(false);
        console.log(venda, itensVenda)
    }

    async function removerItemVenda(index: number, id_item_venda: any) {
        if (id_item_venda === 0) {
            let aux = JSON.parse(JSON.stringify(itensVenda));
            aux.splice(index, 1);
            await setItensVenda(aux);
            let soma = 0;
            aux.forEach((el: any) => {
                soma += parseFloat(el.total_item);
            })
            setVenda({ ...venda, total_venda: soma })
            validarItens(aux)
        } else  if (window.confirm("Deseja remover o item do sistema ?")) {
            setLoading(true);
            await api.get('/VendaDAO.php?removerItemVenda=' + id_item_venda)
                .then(res => {
                    if (res.status === 200) {
                        if (!res.data) {
                            getById();
                            getPagamentos();
                            setAlert(
                                {
                                    show: true,
                                    type: 'success',
                                    title: '',
                                    mainMessage: "Item removido com sucesso",
                                    message2: '',
                                });
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Não é possível remover ou alterar linha",
                                message2: 'Existem dados que depedem dessa linha',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao remover item",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
    }

    async function pesquisarCep() {
        if (cliente.cep.length === 8) {
            await fetch(`https://viacep.com.br/ws/${cliente.cep}/json/`)
                .then(response => {
                    response.json()
                        .then(data => {
                            if (!data.erro) {
                                setCliente({
                                    ...cliente,
                                    cidade: data.localidade,
                                    uf: data.uf,
                                    bairro: data.bairro,
                                    rua: data.logradouro,

                                });
                                if (data.logradouro !== '') {
                                    //aux['rua'] = data.logradouro + ", " + data.bairro;
                                }
                            }
                            else {
                                setCliente({
                                    ...cliente,
                                    cidade: '',
                                    uf: '',
                                    bairro: '',
                                    rua: '',

                                });
                            }
                        })
                }, err => console.log('erro'))
        }
    }

    async function getPagamentos() {
        await api.get('/VendaDAO.php?getPagamentos=' + id_venda)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setPagamentos(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao carregar pagamentos",
                            message2: '',
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar pagamentos",
                        message2: '',
                    });
                }

            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getById() {
        await api.get('/VendaDAO.php?getById=' + id_venda)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            let auxc = JSON.parse(JSON.stringify(cliente))
                            let auxv = JSON.parse(JSON.stringify(venda))
                            Object.keys(cliente).forEach((item) => {
                                auxc[item] = res.data[item];
                            });
                            Object.keys(venda).forEach((item) => {
                                auxv[item] = res.data[item];
                            });
                            setCliente(auxc);
                            setVenda(auxv);
                            setItensVenda(JSON.parse(res.data.itens_venda));
                            validarCliente(auxc);
                            validarItens(JSON.parse(res.data.itens_venda));
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao carregar dados",
                            message2: '',
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar dados",
                        message2: '',
                    });
                }

            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const formElement = event.target as HTMLFormElement; // Realiza type casting para HTMLFormElement
        setValidar(true);
        if (formElement.reportValidity() && venda.fk_usuario != 0) {
            setLoading(true);
            var form = new FormData();
            let ven = JSON.parse(JSON.stringify(venda));
            let cli = JSON.parse(JSON.stringify(cliente));
            let itens = (JSON.stringify(itensVenda));

            form.append("inserir", "inserir");
            Object.keys(ven).forEach((item) => {
                if (item !== 'nome_cliente' && item !== 'nome_usuario') {
                    form.append(item, ven[item] ?? '');
                }
            });
            Object.keys(cli).forEach((item) => {
                form.append(item, cli[item] ?? '');
            });
            form.append("itens", itens);
            await api.post('/VendaDAO.php', form)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.errorInfo) {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao " + (id_venda ? "atualizar" : "cadastrar") + " venda",
                                message2: '',
                            });
                        }
                        else {
                            history.push("/vendas", {
                                alert: {
                                    show: true,
                                    type: 'success',
                                    title: '',
                                    mainMessage: "Venda " + (id_venda ? "atualizado" : "cadastrado") + " com sucesso",
                                    message2: '',
                                }
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao " + (id_venda ? "atualizar" : "cadastrar") + " venda",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
        else {
            setAlert({
                show: true,
                type: 'warning',
                title: '',
                mainMessage: "Preencha os campos corretamente",
                message2: '',
            });
        }
    }

    function imprimirResumo() {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = process.env.REACT_APP_API_URL + '/relatorios/resumoCompra.php';
        form.target = '_blank';
        let itens: any = [];
        itensVenda?.map((el, i) => {
            itens.push({
                produto: [todosProdutos.find(product => product.id_produto == el.fk_produto)?.descricao_produto, el.tipos_variacoes.map((tv) => {
                    console.log(tv);
                    return tv.variacao_selecionada ?
                        ((![38, 39, 40, 41].includes(tv.variacao_selecionada.id_variacao) && tv.descricao_tipo_variacao != "FACÇÃO") ? " " + tv.descricao_tipo_variacao + ": " + tv.variacao_selecionada.descricao_variacao : 'null')
                        : 'null';
                })].join(',').replace(/,?null|/g, ''),
                quantidade: el.quantidade,
                unidade: converterMoeda(el.valor_unitario),
                total: converterMoeda(el.total_item),
                imagem: (el.img_item.includes('base64') ? el.img_item : (process.env.REACT_APP_API_URL) + (el.img_item !== '' ? "imagens_itens_venda/" + el.img_item : imgDefault)),
            })
        })
        let params: any = {
            itens: JSON.stringify(itens),
            total: converterMoeda(venda.total_venda),
            pago: converterMoeda(venda.valor_pago),
            falta: converterMoeda(venda.total_venda - venda.valor_pago > 0 ? venda.total_venda - venda.valor_pago : 0),
            pagamentos: JSON.stringify(pagamentos),
            cliente: JSON.stringify(cliente),
        }

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = params[key];
                console.log(key);
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    function imprimriAdesivo(item: ItemVendaVO) {
        const form = document.createElement('form');
        form.method = 'post';
        form.action = process.env.REACT_APP_API_URL + 'relatorios/imprimirAdesivo.php';
        form.target = '_blank';
        let params: any = {
            imagem: (item.img_item.includes('base64') ? item.img_item : (process.env.REACT_APP_API_URL) + (item.img_item !== '' ? "imagens_itens_venda/" + item.img_item : imgDefault)),
            cliente: cliente.nome_cliente ?? '',
            endereco: cliente.rua ?? '' + ' bairro: ' + cliente.bairro ?? '',
            cidade: cliente.cidade ?? '' + ' - ' + cliente.uf ?? '',
            telefone: cliente.telefone ?? ''
        }

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = params[key];
                console.log(key);
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    async function submitPagamento() {
        if (pagamento.valor_pagamento > 0) {
            setLoading(true);
            var form = new FormData();
            let aux = JSON.parse(JSON.stringify(pagamento));
            aux['fk_venda'] = id_venda;
            form.append("inserirPagamento", "inserirPagamento");
            Object.keys(aux).forEach((item) => {
                form.append(item, aux[item]);
            });
            await api.post('/VendaDAO.php', form)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data?.errorInfo) {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao cadastrar pagamento",
                                message2: res.data.errorInfo,
                            });
                        }
                        else {
                            getById();
                            getPagamentos();
                            setPagamento({ ...pagamento, descricao_pagamento: '', valor_pagamento: 0 })
                            setAlert({
                                show: true,
                                type: 'success',
                                title: '',
                                mainMessage: "Pagamento cadastrado com sucesso",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao cadastrar pagamento",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
        else {
            setAlert({
                show: true,
                type: 'warning',
                title: '',
                mainMessage: "Preencha o valor do pagamento",
                message2: '',
            });
        }
    }

    async function removerPagamento(id: number) {
        if (window.confirm("Deseja remover o pagamento do sistema ?")) {
            setLoading(true);
            await api.get('/VendaDAO.php?removerPagamento=' + id)
                .then(res => {
                    if (res.status === 200) {
                        if (!res.data) {
                            getById();
                            getPagamentos();
                            setAlert(
                                {
                                    show: true,
                                    type: 'success',
                                    title: '',
                                    mainMessage: "Pagamento removido com sucesso",
                                    message2: '',
                                });
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Não é possível remover ou alterar linha",
                                message2: 'Existem dados que depedem dessa linha',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao remover os dados",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
    }

    async function listarDesigners() {
        setLoading(true);
        await api.get('/UsuarioDAO.php?listarDesigners=listarDesigners')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTodosDesigners(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000)
        }
    }, [alert])

    useEffect(() => {
        let session = sessionStorage.getItem("login");
        let user = (session ? JSON.parse(session) : null);
        listarProdutos();
        listarDesigners();
        if (id_venda) {
            getById();
            getPagamentos();
        }
        else {
            inserirItemVenda();
            setLoading(false);
        }
    }, [])

    $('a[data-toggle="tab"]').on('click', function () {
        if ($(this).parent('li').hasClass('disabled')) {
            return false;
        };
    });
    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={2} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3 ">
                            <div className="card-head-cadastrar">
                                <Link to={"/vendas"} className='btn btn-primary mr-3'>
                                    <span className='icon text-white'>
                                        <FaArrowLeft />
                                    </span>
                                </Link>
                                <h6 className="m-0 font-weight-bold text-primary">{id_venda ? 'ATUALIZAR' : 'CADASTRAR '} VENDAS</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit} autoComplete="off">
                                <div className="container card shadow d-flex justify-content-center tabs-container">
                                    <ul className="nav nav-tabs mb-3 " id="tabs-tab" role="tablist">
                                        <li className={"nav-item " + (clienteValido ? 'valido' : '')}>
                                            <a className="nav-link active" id="tabs-cliente-tab" data-toggle="tab" href="#tabs-cliente" role="tab" aria-controls="tabs-homclientee" aria-selected="true">Informações do Cliente</a>
                                        </li>
                                        <li className={"nav-item " + (clienteValido ? '' : 'disabled')} >
                                            <a className="nav-link" id="tabs-itens-tab" data-toggle="tab" href="#tabs-itens" role="tab" aria-controls="tabs-itens" aria-selected="false">Inserir Itens</a>
                                        </li>
                                        <li className={"nav-item " + (clienteValido && itensValido ? '' : 'disabled')} >
                                            <a className="nav-link" id="tabs-finalizar-tab" data-toggle="tab" href="#tabs-finalizar" role="tab" aria-controls="tabs-finalizar" aria-selected="false">Finalizar</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="tabs-tabContent p-3">
                                        {/*Cliente*/}
                                        <div className="tab-pane fade show active" id="tabs-cliente" role="tabpanel" aria-labelledby="tabs-cliente-tab">
                                            <div className="form-row">
                                                <div className="form-group col-md-2 position-relative">
                                                    <label htmlFor="cod_cliente">Código</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="cod_cliente"
                                                        value={cliente.cod_cliente}
                                                        id="cod_cliente"
                                                        required
                                                        disabled={cliente.id_cliente !== 0}
                                                        onBlur={handlerValidationInput}
                                                        onKeyUp={handlerInputDebounce}
                                                        onChange={handlerInput}
                                                        placeholder="0000" />
                                                    <div className="invalid-feedback">
                                                        Obrigatório
                                                    </div>
                                                    <div className="dropdown-clientes">
                                                        {clientes?.map((c) => {
                                                            return (
                                                                <span key={c.id_cliente}
                                                                    onClick={() => {
                                                                        setCliente(c);
                                                                        setVenda({ ...venda, fk_cliente: c.id_cliente })
                                                                        setClientes([]);
                                                                        validarCliente(c);
                                                                        let nomeC = document.getElementById("nome_cliente");
                                                                        if (nomeC) nomeC.className = 'form-control ';

                                                                    }}
                                                                    className="dropdown-item">{c.nome_cliente}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-7">
                                                    <label htmlFor="nome_cliente">Nome completo</label>
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handlerInput}
                                                        onBlur={handlerValidationInput}
                                                        id="nome_cliente"
                                                        name="nome_cliente"
                                                        required
                                                        value={cliente.nome_cliente}
                                                        disabled={cliente.id_cliente !== 0}
                                                        placeholder="Nome completo" />
                                                    <div className="invalid-feedback">
                                                        Obrigatório
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label className="invisible">-</label>
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={() => {
                                                            setCliente(new ClienteVO(0, '', '', '', '', '', 0, '', '', '', '', '', ''));
                                                            validarCliente(new ClienteVO(0, '', '', '', '', '', 0, '', '', '', '', '', ''));
                                                        }}>Outro cliente / Limpar</button>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="email_cliente">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email_cliente"
                                                        value={cliente.email_cliente ?? ''}
                                                        id="email_cliente"
                                                        onChange={handlerInput}
                                                        placeholder="Email" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="telefone">Telefone</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="telefone"
                                                        value={cliente.telefone}
                                                        id="telefone"
                                                        onChange={handlerInput}
                                                        placeholder="00 000000000" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="cpf">CPF/CNPJ</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="cpf"
                                                        value={cliente.cpf}
                                                        id="cpf"
                                                        onChange={handlerInput}
                                                        placeholder="CPF/CNPJ" />
                                                    <div className="invalid-feedback">
                                                        CPF inválido
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-5">
                                                    <label htmlFor="rua">Rua</label>
                                                    <input
                                                        type="text"
                                                        name="rua"
                                                        value={cliente.rua ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="rua"
                                                        placeholder="Rua" />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="numero">Nº</label>
                                                    <input
                                                        type="text"
                                                        name="numero"
                                                        value={cliente.numero ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="numero"
                                                        placeholder="Nº" />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="complemento">Comp.</label>
                                                    <input
                                                        type="text"
                                                        name="complemento"
                                                        value={cliente.complemento ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="complemento"
                                                        placeholder="Casa/Ap." />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="bairro">Bairro</label>
                                                    <input
                                                        type="text"
                                                        name="bairro"
                                                        value={cliente.bairro ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="bairro"
                                                        placeholder="Bairro" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputCity">Cidade</label>
                                                    <input
                                                        type="text"
                                                        name="cidade"
                                                        value={cliente.cidade ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="inputCity"
                                                        placeholder="Cidade" />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputState">Estado</label>
                                                    <input
                                                        type="text"
                                                        name="uf"
                                                        value={cliente.uf ?? ''}
                                                        onChange={handlerInput}
                                                        className="form-control"
                                                        id="inputState"
                                                        placeholder="UF" />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="inputZip">CEP</label>
                                                    <div className="form-row">
                                                        <input
                                                            type="text"
                                                            name="cep"
                                                            value={cliente.cep ?? ''}
                                                            onChange={handlerInput}
                                                            maxLength={8}
                                                            className="form-control col-md-9"
                                                            id="inputZip" placeholder="CEP" />
                                                        <button type='button' className="btn btn-primary col-md-3" onClick={pesquisarCep} title="Pesquisar por CEP">
                                                            <BsSearch className='icon-btn' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Itens*/}
                                        <div className="tab-pane fade" id="tabs-itens" role="tabpanel" aria-labelledby="tabs-itens-tab">

                                            {itensVenda?.map((itemVenda, i) => {
                                                return <div key={i} className="card-item-venda">
                                                    <div className="row">
                                                        <div className="col-md-9 form-row">
                                                            <div className="form-group col-md-7">
                                                                <label htmlFor={"fk_produto_" + i}>Produto</label>
                                                                <select
                                                                    name="fk_produto"
                                                                    onChange={handlerSelectItem}
                                                                    value={itemVenda.fk_produto}
                                                                    data-id={i}
                                                                    disabled={itemVenda.id_item_venda !== 0}
                                                                    className="form-control"
                                                                    id={"fk_produto_" + i}>
                                                                    <option value={0}>Selecione um produto</option>
                                                                    {todosProdutos.map((produto) => {
                                                                        return (
                                                                            <option
                                                                                key={produto.id_produto}
                                                                                value={produto.id_produto}>{produto.descricao_produto}
                                                                            </option>);
                                                                    })}

                                                                </select>
                                                            </div>
                                                            <div className="form-group col-sm-2">
                                                                <label htmlFor={"quantidade_" + i}>Quantidade</label>
                                                                <input
                                                                    type="number"
                                                                    name="quantidade"
                                                                    onChange={handlerInputItem}
                                                                    value={itemVenda.quantidade}
                                                                    data-id={i}
                                                                    className="form-control"
                                                                    id={"quantidade_" + i} />
                                                            </div>
                                                            <div className="form-group col-sm-3">
                                                                <label htmlFor={"valor_unitario_" + i}>Valor und.</label>
                                                                <input
                                                                    type="number"
                                                                    name="valor_unitario"
                                                                    onChange={handlerInputItem}
                                                                    value={itemVenda.valor_unitario}
                                                                    data-id={i}
                                                                    className="form-control"
                                                                    id={"valor_unitario_" + i} />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="form-row card-item-variacao">
                                                                    {itemVenda.tipos_variacoes?.map((item_tipo_variacao, j) => {
                                                                        return (
                                                                            <div key={item_tipo_variacao.id_tipo_variacao} className="form-group col-lg-6">
                                                                                <label
                                                                                    htmlFor={"variacao_selecionada" + item_tipo_variacao.id_tipo_variacao}>
                                                                                    {item_tipo_variacao.descricao_tipo_variacao}
                                                                                </label>
                                                                                <select
                                                                                    name={"variacao_selecionada" + item_tipo_variacao.id_tipo_variacao}
                                                                                    className="form-control"
                                                                                    id={"variacao_selecionada" + item_tipo_variacao.id_tipo_variacao}
                                                                                    data-id-item={i}
                                                                                    data-id-item-variacao={j}
                                                                                    value={item_tipo_variacao.variacao_selecionada?.id_variacao}
                                                                                    onChange={handlerSelectItemVariacao}>
                                                                                    <option value="0">Selecione uma variação</option>
                                                                                    {item_tipo_variacao.variacoes.map((variacao) => {
                                                                                        return (<option key={variacao.id_variacao} value={variacao.id_variacao}>{variacao.descricao_variacao}</option>);
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 form-row">
                                                            <div className="col-12">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => imprimriAdesivo(itemVenda)}
                                                                >
                                                                    <AiFillPrinter className="icon-btn" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger float-right"
                                                                    onClick={() => removerItemVenda(i, itemVenda.id_item_venda)}>
                                                                    <FaTrashAlt className="icon-btn" />
                                                                </button>
                                                            </div>
                                                            <div className="col-12 justify-content-start">
                                                                <div className="">
                                                                    <label className="w-100 mt-3 w-100 img-item-venda border rounded" htmlFor={"imagem" + i}>
                                                                        <img
                                                                            src={itemVenda.img_item.includes('base64') ? itemVenda.img_item : (process.env.REACT_APP_API_URL + 'imagens_itens_venda/' + itemVenda.img_item)}
                                                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                                                const target = e.target as HTMLImageElement;
                                                                                target.src = process.env.REACT_APP_API_URL + imgDefault;
                                                                                target.alt = 'Imagem Alternativa';
                                                                            }}
                                                                            alt="example placeholder"
                                                                        />
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        data-id={i}
                                                                        onChange={handlerInputItem}
                                                                        className="form-control d-none" id={"imagem" + i}
                                                                        accept="image/*" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <label htmlFor={"total_item" + i}>Total: {converterMoeda(itemVenda.total_item ? itemVenda.total_item : 0)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}

                                            <button
                                                className="btn w-100 btn-primary"
                                                type="button"
                                                onClick={inserirItemVenda}
                                            >
                                                <FaPlus className="icon-btn" />
                                            </button>

                                        </div>

                                        {/*Finalizar*/}
                                        <div className="tab-pane fade third" id="tabs-finalizar" role="tabpanel" aria-labelledby="tabs-finalizar-tab">
                                            <div className="form-row card-item-venda position-relative">
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="data">Data da venda</label>
                                                    <input
                                                        type="datetime-local"
                                                        className="form-control"
                                                        name="data"
                                                        value={venda.data}
                                                        id="data"
                                                        onChange={handlerInputVenda}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="data_entrega">Estimativa de entrega</label>
                                                    <input
                                                        type="datetime-local"
                                                        className="form-control"
                                                        name="data_entrega"
                                                        value={venda.data_entrega}
                                                        id="data_entrega"
                                                        onChange={handlerInputVenda}
                                                    />
                                                </div>
                                                <div className="form-group col-md-4 float-right">
                                                    <label htmlFor="data_entrega">Situação do pagamento</label>
                                                    <br />
                                                    {venda.status == 0 ?
                                                        <button type="button" disabled className="btn btn-outline-success w-100 font-weight-bold">Pago</button> :
                                                        <button type="button" disabled className="btn btn-outline-danger w-100 font-weight-bold">Pendente</button>
                                                    }
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="fk_usuario">Designer</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={handlerSelectVenda}
                                                        value={venda.fk_usuario}
                                                        name="fk_usuario"
                                                        id="fk_usuario">
                                                        <option value={0}>Selecione um designer</option>
                                                        {todosDesigners.map((el) => {
                                                            return (<option key={el.id_usuario} value={el.id_usuario}>{el.nome_usuario}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="status_venda">Situação da venda</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={handlerSelectVenda}
                                                        value={venda.status_venda}
                                                        name="status_venda">
                                                        <option value="Entregue">Entregue</option>
                                                        <option value="Em produção">Em produção</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4 group-prioridade">
                                                    <label className="ml-3" htmlFor="prioridade">Para eventos</label>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            id="prioridade"
                                                            name="prioridade"
                                                            value={venda.prioridade}
                                                            checked={(venda.prioridade == 1)}
                                                            onChange={handlerInputVenda}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 d-flex justify-content-between align-items-center mt-4">
                                                    <h5 className="font-weight-bold mb-3">Resumo da compra</h5>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary float-right mb-1"
                                                        onClick={() => imprimirResumo()}
                                                    >
                                                        <AiFillPrinter className="icon-btn" />
                                                        Imprimir resumo
                                                    </button>
                                                </div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Produto</th>
                                                            <th scope="col">Valor und.</th>
                                                            <th scope="col">Quantidade</th>
                                                            <th scope="col">Total</th>
                                                            <th scope="col">Imagem</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itensVenda?.map((el, i) => {
                                                            return <tr key={i}>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>
                                                                    {[todosProdutos.find(product => product.id_produto == el.fk_produto)?.descricao_produto, el.tipos_variacoes.map((tv) => {
                                                                        return tv.variacao_selecionada ? " " + tv.descricao_tipo_variacao + ": " + tv.variacao_selecionada.descricao_variacao : 'null';
                                                                    })].join(',').replace(/,?null|/g, '')}
                                                                </td>
                                                                <td>{converterMoeda(el.valor_unitario)}</td>
                                                                <td>{el.quantidade}</td>
                                                                <td>{converterMoeda(el.total_item)}</td>
                                                                <td><img className="img-table" src={el.img_item.includes('base64') ? el.img_item : (process.env.REACT_APP_API_URL + 'imagens_itens_venda/' + el.img_item)}
                                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                                        const target = e.target as HTMLImageElement;
                                                                        target.src = process.env.REACT_APP_API_URL + imgDefault;
                                                                        target.alt = 'Imagem Alternativa';
                                                                    }} alt="item venda" />
                                                                </td>
                                                            </tr>
                                                        })}

                                                        <tr>
                                                            <td colSpan={4}></td>
                                                            <th className="bg-warning" scope="col">Total venda</th>
                                                            <td className="bg-warning" >{converterMoeda(venda.total_venda)}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <button className="btn btn-primary">{id_venda ? 'Atualizar ' : 'Cadastrar '}venda</button>
                                            {id_venda ?
                                                <>
                                                    <hr />
                                                    <small className="text-danger">Antes de inserir um novo pagamento, certifique-se de que os dados da venda já estão cadastrados!</small>
                                                    <div className="card-item-venda form-row">
                                                        <div className="font-weight-bold col-md-12">Novo pagamento</div>
                                                        <br />
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="valor_pagamento">Valor pago</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="valor_pagamento"
                                                                value={pagamento.valor_pagamento}
                                                                id="valor_pagamento"
                                                                onChange={handlerInputPagameneto}
                                                                placeholder="0,00" />
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="descricao_pagamento">Descrição</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="descricao_pagamento"
                                                                value={pagamento.descricao_pagamento}
                                                                id="descricao_pagamento"
                                                                onChange={handlerInputPagameneto}
                                                                placeholder="Descrição" />
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <button
                                                                type="button"
                                                                disabled={pagamento.valor_pagamento <= 0}
                                                                className="btn btn-primary"
                                                                onClick={() => submitPagamento()}
                                                            >Adicionar pagamento</button>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="card-item-venda">
                                                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                                                            <h5 className="font-weight-bold mb-3">Pagamentos efetuados</h5>
                                                        </div>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Valor</th>
                                                                    <th scope="col">Data</th>
                                                                    <th scope="col">Descrição</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pagamentos?.map((el, i) => {
                                                                    return <tr key={i}>
                                                                        <th scope="row">{i + 1}</th>
                                                                        <td>{converterMoeda(el.valor_pagamento)}</td>
                                                                        <td>{new Date(el.data_pagamento).toLocaleString()}</td>
                                                                        <td>{el.descricao_pagamento}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className='btn btn-danger btn-icon-split m-1'
                                                                                onClick={() => removerPagamento(el.id_pagamento)}
                                                                            >
                                                                                <span className='icon text-white-50'>
                                                                                    <FaTrash />
                                                                                </span>
                                                                                <span className='text-white'>Remover</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                                <tr>
                                                                    <td className="bg-danger text-white">Valor restante</td>
                                                                    <td className="bg-danger text-white" >{converterMoeda((venda.total_venda - venda.valor_pago) > 0 ? venda.total_venda - venda.valor_pago : 0)}</td>
                                                                    <td></td>
                                                                    <td className="bg-success text-white" >Valor pago</td>
                                                                    <td className="bg-success text-white" >{converterMoeda(venda.valor_pago)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </>
    )
}

export default CadastrarVendas;