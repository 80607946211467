export function isCpfValid(cpf) {
    // Step 1: Remove non-digit characters
    const cleanedCpf = cpf.replace(/\D/g, '');
  
    // Step 2: Check if CPF has 11 digits
    if (cleanedCpf.length !== 11) {
      return false;
    }
  
    // Step 3: Calculate verification digits
    const cpfArray = cleanedCpf.split('').map(Number);
    const sumFirstVerificationDigit = cpfArray
      .slice(0, 9)
      .map((digit, index) => digit * (10 - index))
      .reduce((accumulator, value) => accumulator + value, 0);
  
    const firstVerificationDigit = (sumFirstVerificationDigit * 10) % 11;
    const expectedFirstVerificationDigit = cpfArray[9];
  
    const sumSecondVerificationDigit = cpfArray
      .slice(0, 10)
      .map((digit, index) => digit * (11 - index))
      .reduce((accumulator, value) => accumulator + value, 0);
  
    const secondVerificationDigit = (sumSecondVerificationDigit * 10) % 11;
    const expectedSecondVerificationDigit = cpfArray[10];
  
    // Step 4: Compare calculated verification digits with the last two digits of the CPF
    return (
      firstVerificationDigit === expectedFirstVerificationDigit &&
      secondVerificationDigit === expectedSecondVerificationDigit
    );
  }

  export function converterMoeda(valor) {
    return parseFloat(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
}

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
      const later = () => {
          clearTimeout(timeout);
          func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
};

export const formatCpf = (value) => {
  const cpfRegex = /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/;
  const match = value.match(cpfRegex);

  if (!match) return value;

  return !match[2]
      ? match[1]
      : `${match[1]}.${match[2]}${match[3] ? `.${match[3]}` : ''}${match[4] ? `-${match[4]}` : ''}`;
};