export default class ProdutoVO {
    id_usuario: number;
    nome_usuario: string;
    login: string;
    senha: string;
    tipo_usuario: number;
    constructor(
        id_usuario: number,
        nome_usuario: string,
        login: string,
        senha: string,
        tipo_usuario: number
    ) {
        this.id_usuario = id_usuario;
        this.nome_usuario = nome_usuario;
        this.login = login;
        this.senha = senha;
        this.tipo_usuario = tipo_usuario;
    }
}