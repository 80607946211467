import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react';
import './Gastos.css'; // Assuming you have a CSS file for styling
import SideBar from '../Components/SideBar';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../api'; // Import your API functions
import AlertMessage from '../Components/AlertMessage'; // Import your AlertMessage component
import Loading from '../Components/Loading'; // Import your Loading component
import GastoVO from '../VO/GastosVO';

interface Props { }

interface ParamTypes {
    id_gasto: string; // Update to match your route parameter name
}

const CadastrarGastos: React.FC<Props> = (props) => {
    const [gasto, setGasto] = useState(new GastoVO(0, '', 0, '', 'empresarial')); // Assuming GastoVO structure
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        type: '',
        title: '',
        mainMessage: '',
        message2: '',
    });
    const { id_gasto } = useParams<ParamTypes>(); // Update to match your route parameter name
    const history = useHistory();

    async function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        var form = new FormData();
        let aux = JSON.parse(JSON.stringify(gasto));
        form.append("inserirOuAtualizar", "inserirOuAtualizar");
        Object.keys(aux).forEach((item) => {
            form.append(item, aux[item]);
        });
        await api.post('/gastosDAO.php', form)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.errorInfo) {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao " + (id_gasto ? "atualizar" : "cadastrar") + " gasto",
                            message2: res.data.errorInfo,
                        });
                    } else {
                        history.push("/gastos", { // Update to match your route
                            alert: {
                                show: true,
                                type: 'success',
                                title: '',
                                mainMessage: "Gasto " + (id_gasto ? "atualizado" : "cadastrado") + " com sucesso",
                                message2: '',
                            },
                        });
                    }
                } else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao " + (id_gasto ? "atualizar" : "cadastrar") + " gasto",
                        message2: '',
                    });
                }
            })
            .catch((err) => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a API",
                    message2: '',
                });
            })
            .finally(() => setLoading(false));
    }

    async function getById() {
        await api.get('/gastosDAO.php?getById=' + id_gasto) // Update to match your route
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setGasto(res.data);
                        } else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    } else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao carregar gasto",
                            message2: '',
                        });
                    }
                } else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar gasto",
                        message2: '',
                    });
                }
            })
            .catch((err) => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a API",
                    message2: '',
                });
            })
            .finally(() => setLoading(false));
    }

    function handlerInputItem(event: ChangeEvent<HTMLInputElement>) {
        let aux = JSON.parse(JSON.stringify(gasto));
        aux[event.target.name] = event.target.value;
        setGasto(aux);
    }
    function handlerSelectItem(event: ChangeEvent<HTMLSelectElement>) {
        let aux = JSON.parse(JSON.stringify(gasto));
        aux[event.target.name] = event.target.value;
        setGasto(aux);
    }

    useEffect(() => {
        if (id_gasto) {
            getById();
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000);
        }
    }, [alert]);

    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={7} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3 ">
                            <div className="card-head-cadastrar">
                                <Link to={"/gastos"} className='btn btn-primary mr-3'> {/* Update to match your route */}
                                    <span className='icon text-white'>
                                        <FaArrowLeft />
                                    </span>
                                </Link>
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {id_gasto ? "ATUALIZAR" : "CADASTRAR"} GASTO
                                </h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit}>
                                <div className="container card shadow d-flex justify-content-center tabs-container">
                                    <div className="form-row">
                                        <div className=" form-group col-md-12">
                                            <label htmlFor="descricao_gasto">Descrição</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="descricao_gasto"
                                                name="descricao_gasto"
                                                placeholder="Descrição"
                                                value={gasto.descricao_gasto}
                                                onChange={handlerInputItem}
                                                required
                                            />
                                        </div>
                                        <div className=" form-group col-md-5">
                                            <label htmlFor="total_gasto">Total</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="total_gasto"
                                                name="total_gasto"
                                                placeholder="Total"
                                                value={gasto.total_gasto}
                                                onChange={handlerInputItem}
                                                required
                                            />
                                        </div>
                                        <div className=" form-group col-md-4">
                                            <label htmlFor="data_gasto">Data</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="data_gasto"
                                                name="data_gasto"
                                                placeholder="Data"
                                                value={gasto.data_gasto}
                                                onChange={handlerInputItem}
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="fk_produto_">Tipo de gasto</label>
                                            <select
                                                className="form-control"
                                                id="tipo_gasto"
                                                name="tipo_gasto"
                                                value={gasto.tipo_gasto}
                                                onChange={handlerSelectItem}
                                                required>
                                                <option value={'empresarial'}>Empresarial</option>
                                                <option value={'mila'}>MILÃ</option>
                                                <option value={'jr'}>JR FARDAMENTOS</option>
                                                <option value={'jla'}>JLA</option>
                                                <option value={'bones_ze'}>BONÉS ( ZÉ )</option>
                                                <option value={'digitalize_beto'}>DIGITALIZE ( BETO )</option>
                                                <option value={'grafica_rapida'}>( GRÁFICA RAPIDA )</option>
                                                <option value={'pd_ricardo'}>PROJETO DE DEUS ( RICARDO )</option>
                                                <option value={'grafica_didiu'}>FIXAR GRÁFICA ( DIDIU )</option>
                                                <option value={'copo_brinde'}>COPO ( Monyque Santana Encaixe Brinde )</option>                                               
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <button
                                                className="btn btn-primary float-right"
                                                type="submit"
                                            >
                                                {id_gasto ? "Atualizar" : "Cadastrar"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </>
    );
};

export default CadastrarGastos;
