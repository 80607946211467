import React from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Login from './Login/Login';
import Dashboard from './Dashboard';
import Vendas from './Vendas';
import Produtos from './Produtos';
import Variacoes from './Variacoes';
import TiposVariacao from './TiposVariacao';
import CadastrarVendas from './Vendas/CadastrarVendas';
import CadastrarProdutos from './Produtos/CadastrarProdutos';
import CadastrarTipoVariacao from './TiposVariacao/CadastrarTipoVariacao';
import CadastrarVariacoes from './Variacoes/CadastrarVariacoes';
import Clientes from './Clientes';
import CadastrarClientes from './Clientes/CadastrarClientes';
import Usuarios from './Usuarios';
import CadastrarUsuarios from './Usuarios/CadastrarUsuarios';
import Gastos from './Gastos';
import CadastrarGastos from './Gastos/CadastrarGastos';


interface Props {
    location: any
}
const Page404: React.FC<Props> = (props) => {
    return (<div className="error-container">
        <div className="error-code">404</div>
        <div className="error-description">Page Not Found</div>
    </div>)
}

const PrivateRoute = ({ component, ...rest }: any) => {
    let user: any = null;
    let session = sessionStorage.getItem("login");
    user = (session ? JSON.parse(session) : null);
    const routeComponent = (props: any) => (
        (user && user.id_usuario !== 0)
            ? React.createElement(component, props)
            : <Redirect to={{ pathname: '/' }} />
    );
    return <Route {...rest} render={routeComponent} />;
};
const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route component={Login} exact path="/" />
                <PrivateRoute component={Dashboard} path="/dashboard" />
                <PrivateRoute component={Vendas} path="/vendas" />
                <PrivateRoute component={CadastrarVendas} exact path="/cadastrarVendas" />
                <PrivateRoute component={CadastrarVendas} path="/cadastrarVendas/:id_venda" />
                <PrivateRoute component={Produtos} path="/produtos" />
                <PrivateRoute component={CadastrarProdutos} exact path="/cadastrarProdutos" />
                <PrivateRoute component={CadastrarProdutos} path="/cadastrarProdutos/:id_produto" />
                <PrivateRoute component={TiposVariacao} path="/tiposVariacoes" />
                <PrivateRoute component={CadastrarTipoVariacao} exact path="/cadastrarTipoVariacao/" />
                <PrivateRoute component={CadastrarTipoVariacao} path="/cadastrarTipoVariacao/:id_tipo_variacao" />
                <PrivateRoute component={Variacoes} path="/variacoes" />
                <PrivateRoute component={CadastrarVariacoes} exact path="/cadastrarVariacoes" />
                <PrivateRoute component={CadastrarVariacoes} path="/cadastrarVariacoes/:id_variacao" />
                <PrivateRoute component={Clientes} path="/clientes" />
                <PrivateRoute component={CadastrarClientes} exact path="/cadastrarClientes" />
                <PrivateRoute component={CadastrarClientes} path="/cadastrarClientes/:id_cliente" />
                <PrivateRoute component={Usuarios} path="/usuarios" />
                <PrivateRoute component={CadastrarUsuarios} exact path="/cadastrarUsuarios" />
                <PrivateRoute component={CadastrarUsuarios} path="/cadastrarUsuarios/:id_usuario" />
                <PrivateRoute component={Gastos} path="/gastos" />
                <PrivateRoute component={CadastrarGastos} exact path="/cadastrarGastos" />
                <PrivateRoute component={CadastrarGastos} path="/cadastrarGastos/:id_gasto" />
                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;