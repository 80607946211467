import React, { useState, useEffect, ChangeEvent } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import './styles/Table.css';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid'

interface Props {
    object: any,
    data: Array<any>,
    action: any,
    id: string,
    filterColumns: Array<string>
}

const Table: React.FC<Props> = (props) => {
    const [columns, setColumns] = useState(Array<any>);
    const [filter, setFilter] = useState('');
    const [mesRelatorio, setMesRelatorio] = useState('');
    const [listar, setListar] = useState('Todas as vendas');

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        setListar(event.target.value);
    }

    function handleMes(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setMesRelatorio(event.target.value);
        setListar('mes');
    }

    useEffect(() => {
        var columns: TableColumn<any>[] = [];
        columns.push({
            cell: (row) => <>
                <Link to={props.action.editar + row[props.id]} className='btn btn-warning btn-icon-split m-1'>
                    <span className='icon text-white-50'>
                        <FaPen />
                    </span>
                    <span className='text-white'>Editar</span>
                </Link>
                <a className='btn btn-danger btn-icon-split m-1'
                    onClick={() => props.action.excluir(row[props.id])}>
                    <span className='icon text-white-50'>
                        <FaTrash />
                    </span>
                    <span className='text-white'>Remover</span>
                </a>
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '250px',
            name: 'Ações'
        });
        Object.keys(props.object).forEach((item, i) => {
            let col: TableColumn<any> = {
                name: props.object[item].name,
                selector: row => row[item],
                sortable: true,
                wrap: (props.object[item].width !== 'auto'),
                sortFunction: (rowA: any, rowB: any) => {
                    if (typeof rowA[item] === 'string' && typeof rowB[item] === 'string') {
                        const a = rowA[item]?.toLowerCase();
                        const b = rowB[item]?.toLowerCase();

                        if (a > b) {
                            return 1;
                        }

                        if (b > a) {
                            return -1;
                        }
                    }
                    else {
                        const a = rowA[item];
                        const b = rowB[item];

                        if (a > b) {
                            return 1;
                        }

                        if (b > a) {
                            return -1;
                        }
                    }

                    return 0;
                },
                right: false,
                width: props.object[item].width
            }
            columns.push(col);
        });

        setColumns(columns);
    }, [])

    {/*actions={<ModalForm open={open} onClose={handleClose} onClick={handleClickOpen}  reload={fetchMotorista} loadId={editaID}/> }*/ }
    return (
        <StyleSheetManager
            enableVendorPrefixes
            shouldForwardProp={(propName, elementToBeRendered) => {
                return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
            }}
            {...props}>
            <div className="w-100 d-flex justify-content-between" >
                <Link to={props.action.adicionar} className='btn btn-success btn-icon-split mb-2'>
                    <span className='icon text-white-50'>
                        <FaPlus />
                    </span>
                    <span className='text-white'>Adicionar</span>
                </Link>

                <div className="d-flex justify-content-between">
                    {props.id == 'id_venda' ?
                        <>
                            <div className="form-group col-md-4">
                                <input
                                    type="month"
                                    className="form-control"
                                    name="data"
                                    id="data"
                                    value={mesRelatorio}
                                    onChange={handleMes}
                                />
                            </div>
                            <select
                                className="custom-select form-control mr-3"
                                id="listar"
                                onChange={handleChange}
                                value={listar}
                            >
                                <option value="Todas as vendas">Todas as vendas</option>
                                <option value="evento">Para evento</option>
                                <option value="Entrega em menos de 7 dias">Entrega em menos de 7 dias</option>
                                <option value="Pagamento finalizado">Pagamento finalizado</option>
                                <option value="Pagamento pendente">Pagamento pendente</option>
                                <option value="Entregue">Entregue</option>
                                <option value="Em produção">Em produção</option>
                                <option value="Atrasados">Atrasados</option>
                            </select>
                        </> : ''
                    }

                    <input
                        className="form-control"
                        type="text"
                        name="search"
                        placeholder="Pesquisar"
                        onChange={(e: any) => {
                            setFilter(e.target.value)
                        }}
                        aria-label="Search" />
                </div>
            </div>
            <DataTable
                title=""
                data={props.data.filter((el) => {
                    if (filter === '') {
                        return true;
                    }
                    else {
                        let count = 0;
                        props.filterColumns.map((filtro) => {
                            {
                                count = el[filtro]?.toLowerCase().includes(filter.toLocaleLowerCase()) ? count + 1 : count;
                            }
                        })
                        return count > 0;
                    }
                }).filter((el) => {
                    switch (listar) {
                        case 'Entrega em menos de 7 dias':
                            if (el.data_entrega) {
                                const now = new Date(); // Data de hoje
                                const dataDestinoStr = el.data_entrega;
                                const [dataPart, _horaPart] = dataDestinoStr.split(' - ');
                                const [dia, mes, ano] = dataPart.split('/');
                                const past = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
                                const diff = (past.getTime() - now.getTime()); // Subtrai uma data pela outra
                                const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
                                if (diff > 0 && days <= 7) {
                                    return true;
                                }
                            }
                            return false;
                        case 'Pagamento finalizado':
                            if (el.status == 'Pago') {
                                return true;
                            }
                            return false;
                        case 'Pagamento pendente':
                            if (el.status == 'Pendente') {
                                return true;
                            }
                            return false;
                        case 'Em produção':
                            if (el.status_venda == 'Em produção') {
                                return true;
                            }
                            return false;
                        case 'Entregue':
                            if (el.status_venda == 'Entregue') {
                                return true;
                            }
                            return false;
                        case 'Atrasados':
                            if (el.data_entrega && el.status_venda != "Entregue") {
                                const now = new Date(); // Data de hoje
                                const dataDestinoStr = el.data_entrega;
                                const [dataPart, _horaPart] = dataDestinoStr.split(' - ');
                                const [dia, mes, ano] = dataPart.split('/');
                                const past = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
                                const diff = (past.getTime() - now.getTime()); // Subtrai uma data pela outra
                                if (diff < 0) {
                                    return true;
                                }
                            }
                            return false;
                        case 'mes':
                            var dataFormato1 = mesRelatorio;
                            var dataFormato2 = el.data;
                            var partesDataFormato2 = dataFormato2.match(/(\d{2})\/(\d{2})\/(\d{4})/);
                            var mesAnoFormato2 = partesDataFormato2[3] + '-' + partesDataFormato2[2];
                            console.log(mesAnoFormato2, dataFormato1);
                            if (mesAnoFormato2 === dataFormato1) {
                                return true;
                            }
                            return false;
                        case 'evento':
                            if (el.prioridade == 'Para evento') {
                                return true;
                            }
                            return false;
                        default:
                            return true;
                    }
                })}
                progressPending={false}
                columns={columns}
                pagination
                noDataComponent="Nenhum dado encontrado..."
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Linhas por página',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'All'
                    }
                }
            />
        </StyleSheetManager>
    )
}

export default Table;