import React from 'react';
import './styles/AlertMessage.css';

interface Props {
    show: boolean;
    type: string;
    title?: string;
    mainMessage: string;
    message2?: string;
}

const AlertMessage: React.FC<Props> = (props) => {
    return (
        <div className={"alert alert-"+ props.type + ((props.show)? " active" : "")} role="alert">
            {props.title ? <h4 className="alert-heading">{props.title}</h4> : ''}
            <p>{props.mainMessage}</p>
            {props.message2 ? <>
                <hr />
                <p className="mb-0">{props.message2}</p>
            </> : ''}
        </div>
    )
}

export default AlertMessage;