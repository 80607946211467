import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,     
});

api.interceptors.request.use((config) => {
    config.headers["Accept"] = "application/json, text/plain, */*";
    config.headers['X-Authentication-Token'] = process.env.REACT_APP_API_KEY;
    return config;
}, (error) => {
    // Do something with request error
    console.log('Error occured while request');
    return Promise.reject(error);
});

export default api;