export default class VendaVO {
    id_venda: number;
    fk_cliente: number;
    total_venda: number;
    valor_pago: number;
    fk_usuario: number;
    status: number;
    status_venda: string;
    nome_cliente: string;
    cod_cliente: string;
    nome_usuario: string;
    data: string;
    data_entrega: string;
    prioridade: number;
    constructor(
        id_venda: number,
        fk_cliente: number,
        valor_pago: number,
        total_venda: number,
        fk_usuario: number,
        status: number,
        status_venda: string,
        nome_usuario: string,
        nome_cliente: string,
        cod_cliente: string,
        data: string,
        data_entrega: string,
        prioridade: number
    ) {
        this.id_venda = id_venda;
        this.fk_cliente = fk_cliente;
        this.total_venda = total_venda;
        this.fk_usuario = fk_usuario;
        this.nome_usuario = nome_usuario;
        this.nome_cliente = nome_cliente;
        this.valor_pago = valor_pago;
        this.status = status;
        this.status_venda = status_venda;
        this.data_entrega = data_entrega;
        this.data = data;
        this.cod_cliente = cod_cliente;
        this.prioridade = prioridade;
    }
}