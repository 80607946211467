import React from 'react';
import '../Dashboard.css';

interface Props {
    titulo: string;
    ganhos: string;
    vendas: number;
}

const CardValores: React.FC<Props> = (props) => {

    return (
        <div className="col-xl-3 col-md-6 mb-6 card-graficos">
            <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                    <h6 className="card-title font-weight-bold">{props.titulo}</h6>
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">ganhos</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800" id="vendasDia">{props.ganhos}</div>
                        </div>
                        <div className="col-auto">
                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                    </div>
                    <br />
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">vendas</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800" id="qteDia">{props.vendas}</div>
                        </div>
                        <div className="col-auto">
                            <i className="fas fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardValores;