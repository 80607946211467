import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react';
import './Variacoes.css';
import SideBar from '../Components/SideBar';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../api';
import AlertMessage from '../Components/AlertMessage';
import Loading from '../Components/Loading';
import VariacaoVO from '../VO/VariacaoVO';
import TipoVariacaoVO from '../VO/TipoVariacaoVO';

interface Props {
}

interface ParamTypes {
    id_variacao: string
}


const CadastrarVariacoes: React.FC<Props> = (props) => {
    const [tiposVariacao, setTiposVariacao] = useState(Array<TipoVariacaoVO>);
    const [variacao, setVariacao] = useState(new VariacaoVO(0, 0, '', '', ''));
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        type: '',
        title: '',
        mainMessage: '',
        message2: '',
    });
    const { id_variacao } = useParams<ParamTypes>();
    const history = useHistory();

    async function listarTiposVariacao() {
        setLoading(true);
        await api.get('/TipoVariacaoDAO.php?listarTodos=listarTodos')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTiposVariacao(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        var form = new FormData();
        let aux = JSON.parse(JSON.stringify(variacao));
        form.append("inserirOuAtualizar", "inserirOuAtualizar");
        Object.keys(aux).forEach((item) => {
            form.append(item, aux[item]);
        });
        await api.post('/VariacaoDAO.php', form)
            .then(res => {
                if (res.status === 200) {
                    if (res.data?.errorInfo) {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao " + (id_variacao ? "atualizar" : "cadastrar") + " variacao",
                            message2: res.data.errorInfo,
                        });
                    }
                    else {
                        history.push("/variacoes", {
                            alert: {
                                show: true,
                                type: 'success',
                                title: '',
                                mainMessage: "Variacao " + (id_variacao ? "atualizado" : "cadastrado") + " com sucesso",
                                message2: '',
                            }
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao " + (id_variacao ? "atualizar" : "cadastrar") + " variacao",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getById() {
        await api.get('/VariacaoDAO.php?getById=' + id_variacao)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setVariacao(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao carregar variação",
                            message2: '',
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar variação",
                        message2: '',
                    });
                }

            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    function handlerInputItem(event: ChangeEvent<HTMLInputElement>) {
        setVariacao({ ...variacao, descricao_variacao: event.target.value });
    }

    function handlerSelectItem(event: ChangeEvent<HTMLSelectElement>) {
        setVariacao({ ...variacao, fk_tipo_variacao: parseInt(event.target.value) });
    }

    useEffect(() => {
        listarTiposVariacao();
        if (id_variacao) {
            getById();
        }
        else {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000)
        }
    }, [alert])

    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={5} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3 ">
                            <div className="card-head-cadastrar">
                                <Link to={"/variacoes"} className='btn btn-primary mr-3'>
                                    <span className='icon text-white'>
                                        <FaArrowLeft />
                                    </span>
                                </Link>
                                <h6 className="m-0 font-weight-bold text-primary">{id_variacao ? "ATUALIZAR" : "CADASTRAR"} VARIAÇÕES</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit}>
                                <div className="container card shadow d-flex justify-content-center tabs-container">
                                    <div className="form-row">
                                        <div className=" form-group col-12">
                                            <label htmlFor="descricao_variacao">Descrição</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="descricao_variacao"
                                                placeholder="Digite a variação do produto"
                                                value={variacao.descricao_variacao}
                                                onChange={handlerInputItem} required />
                                        </div>
                                        <div className="form-group col-12 col-sm-6">
                                            <select
                                                className="form-control"
                                                onChange={handlerSelectItem}
                                                required
                                                value={variacao.fk_tipo_variacao}
                                                name="fk_produto">
                                                <option value={0}>Selecione o tipo de variação do produto</option>
                                                {tiposVariacao.map((tv) => {
                                                    return (<option key={tv.id_tipo_variacao} value={tv.id_tipo_variacao}>{tv.descricao_tipo_variacao + " - " + tv.descricao_produto}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="form-group col-12">
                                            <button className="btn btn-primary float-right" type="submit">{id_variacao ? "Atualizar" : "Cadastrar"}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </>
    )
}

export default CadastrarVariacoes;