import React, { useEffect, useState } from 'react';
import './Usuarios.css';
import SideBar from '../Components/SideBar';
import api from '../api';
import UsuarioVO from '../VO/UsuarioVO';
import AlertMessage from '../Components/AlertMessage';
import Loading from '../Components/Loading';
import Table from '../Components/Table';

interface Props {
    location: any;
}

const Usuarios: React.FC<Props> = (props) => {
    const [todosUsuarios, setTodosUsuarios] = useState(Array<UsuarioVO>);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(props.location.state && props.location.state.alert ?
        props.location.state.alert :
        {
            show: false,
            type: '',
            title: '',
            mainMessage: '',
            message2: '',
        });
    async function listarUsuarios() {
        setLoading(true);
        await api.get('/UsuarioDAO.php?listarTodos=listarTodos')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTodosUsuarios(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function remover(id: number) {
        if (window.confirm("Deseja remover o usuario do sistema ?")) {
            setLoading(true);
            await api.get('/UsuarioDAO.php?remover=' + id)
                .then(res => {
                    if (res.status === 200) {
                        if (!res.data) {
                            setAlert(
                                {
                                    show: true,
                                    type: 'success',
                                    title: '',
                                    mainMessage: "Usuario removido com sucesso",
                                    message2: '',
                                });
                            listarUsuarios();
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Não é possível remover ou alterar linha",
                                message2: 'Existem dados que depedem dessa linha',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao remover os dados",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
    }

    useEffect(() => {
        listarUsuarios();
    }, [])
    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000)
        }
    }, [alert])
    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={7} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">USUÁRIOS</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Table
                                    object={
                                        {
                                            id_usuario: { name: 'cod', width: 'auto' },
                                            nome_usuario: { name: 'nome', width: 'auto' },
                                        }
                                    }
                                    action={{
                                        excluir: remover,
                                        editar: "/cadastrarUsuarios/",
                                        adicionar: "/cadastrarUsuarios"
                                    }}
                                    id={"id_usuario"}
                                    data={todosUsuarios}
                                    filterColumns={['nome_usuario']} />
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default Usuarios;