import React, { ChangeEvent, FormEvent, useState } from 'react';
import './Login.css';
import api from '../api';
import { useHistory } from 'react-router-dom';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import Loading from '../Components/Loading';

const Login: React.FC = () => {
    const history = useHistory();
    const [validar, setValidar] = useState(false);
    const [verSenha, setVerSenha] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [err, setErr] = useState('');
    const [login, setLogin] = useState({
        login: '',
        senha: ''
    });
    async function handlerLogin(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setCarregando(true);
        let form_data = new FormData();
        form_data.append('login', login.login);
        form_data.append('senha', login.senha);
        setValidar(true);
        var erraux = '';
        if (login.senha !== '' && login.login !== '') {
            await api.post("/UsuarioDAO.php", form_data).then(res => {
                if (res.status === 200 && res.data && !res.data.errorInfo) {
                    sessionStorage.clear();
                    sessionStorage.setItem("login", JSON.stringify(res.data));
                    history.push("/dashboard");
                }
                else {
                    erraux = "Falha ao realizar login";
                }
                setCarregando(false);
            }).catch(res => {
                setCarregando(false);
                erraux = "Falha ao realizar login";
                console.log(res)
            });
        }
        else {
            setCarregando(false);
            erraux = ("Preencha todos os campos")
        }
        setErr(erraux);

    }
    function handlerCampos(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === 'sen') {
            setLogin({ ...login, senha: event.target.value });
        }
        else {
            setLogin({ ...login, login: event.target.value });
        }
    }
    return (
        <div className="login-container">
            {carregando ? <Loading /> : ''}
            <form className="needs-validation" onSubmit={handlerLogin}>
                <h1>LOGIN</h1>
                <div className="campos form-row">
                    <div className="form-group col-md-12 ">
                        <label htmlFor="mat">Nome</label>
                        <input type="text" name="login" className="form-control" id="mat" autoFocus
                            onChange={handlerCampos} value={login.login} placeholder="Digite seu login" />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="sen">Senha</label>
                        <div className="input-group mb-3">
                            <input type={verSenha ? "text" : "password"} autoComplete="on"
                                aria-describedby="basic-addon2"
                                className="form-control senha-inp" name="sen" id="sen"
                                onChange={handlerCampos} value={login.senha} placeholder="Digite sua senha" />
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon2" onClick={() => setVerSenha((verSenha ? false : true))}>
                                    {verSenha ? <BsFillEyeFill className="icon-senha" /> : <BsFillEyeSlashFill className="icon-senha" />}
                                </span>
                            </div>
                        </div>
                        {(validar) ? <><small className="inv">{err}</small> <br /> </> : ''}
                    </div>
                    <div>
                        <button type='submit' className="btn btn-primary">Entrar</button>
                    </div>
                </div>
                <div className="leeds-login-container">
                    <small>Desenvolvido por <small className="leeds-login"> LEEDS</small></small>
                    <small>V1.3.0</small>
                </div>
            </form>

        </div>
    )
}

export default Login;