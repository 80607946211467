import ItemVariacaoVO from "./ItemVariacaoVO";
import TipoVariacaoVO from "./TipoVariacaoVO";

export default class ItemVendaVO {
    id_item_venda: number;
    fk_produto: number;
    fk_venda: number;
    quantidade: number;
    valor_unitario: number;
    total_item: number;
    img_item: string;
    tipos_variacoes: Array<TipoVariacaoVO>;
    itens_variacoes: Array<ItemVariacaoVO>;
    constructor(
        id_item_venda: number,
        fk_produto: number,
        fk_venda: number,
        quantidade: number,
        valor_unitario: number,
        total_item: number,
        img_item: string,
        tipos_variacoes: Array<TipoVariacaoVO>,
        item_variacoes: Array<ItemVariacaoVO>,
    ) {
        this.id_item_venda = id_item_venda;
        this.fk_produto = fk_produto;
        this.fk_venda = fk_venda;
        this.quantidade = quantidade;
        this.total_item = total_item;
        this.img_item = img_item;
        this.valor_unitario = valor_unitario;
        this.tipos_variacoes = tipos_variacoes;
        this.itens_variacoes = item_variacoes;
    }
}