export default class VariacaoVO {
    id_variacao: number;
    fk_tipo_variacao: number;
    descricao_variacao: string;
    descricao_produto: string;
    descricao_tipo_variacao: string;
    constructor(
        id_variacao: number,
        fk_tipo_variacao: number,
        descricao_variacao: string,
        descricao_produto: string,
        descricao_tipo_variacao: string,
    ) {
        this.id_variacao = id_variacao;
        this.fk_tipo_variacao = fk_tipo_variacao;
        this.descricao_variacao = descricao_variacao;
        this.descricao_tipo_variacao = descricao_tipo_variacao;
        this.descricao_produto = descricao_produto;
    }
}