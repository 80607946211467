export default class PagamentoVO {
    id_pagamento: number;
    fk_venda: number;
    valor_pagamento: number;
    descricao_pagamento: string;
    data_pagamento: Date | string;

    constructor(
        id_pagamento: number,
        fk_venda: number,
        valor_pagamento: number,
        descricao_pagamento: string,
        data_pagamento: Date | string
    ) {
        this.id_pagamento = id_pagamento;
        this.fk_venda = fk_venda;
        this.valor_pagamento = valor_pagamento;
        this.descricao_pagamento = descricao_pagamento;
        this.data_pagamento = data_pagamento;
    }
}