import React from 'react';
import './styles/Loading.css';

const Loading: React.FC = () => {
    return (
        <div className="loading">
            <div className="loader"></div>
        </div>
    )
}

export default Loading;