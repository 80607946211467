import React, { useState, useEffect } from 'react';
import './Vendas.css';
import SideBar from '../Components/SideBar';
import Loading from '../Components/Loading';
import AlertMessage from '../Components/AlertMessage';
import Table from '../Components/Table';
import api from '../api';
import VendaVO from '../VO/VendaVO';

interface Props {
    location: any;
}
const Vendas: React.FC<Props> = (props) => {
    const [vendas, setVendas] = useState(Array<VendaVO>);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(props.location.state && props.location.state.alert ?
        props.location.state.alert :
        {
            show: false,
            type: '',
            title: '',
            mainMessage: '',
            message2: '',
        });
    async function listarVendas() {
        setLoading(true);
        await api.get('/VendaDAO.php?listarTodos=listarTodos')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setVendas(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function remover(id: number) {
        if (window.confirm("Deseja remover a venda do sistema ?")) {
            setLoading(true);
            await api.get('/VendaDAO.php?remover=' + id)
                .then(res => {
                    if (res.status === 200) {
                        if (!res.data) {
                            setAlert(
                                {
                                    show: true,
                                    type: 'success',
                                    title: '',
                                    mainMessage: "Tipo de venda removida com sucesso",
                                    message2: '',
                                });
                            listarVendas();
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Não é possível remover ou alterar linha",
                                message2: 'Existem dados que depedem dessa linha',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao remover os dados",
                            message2: '',
                        });
                    }
                }).catch(err => {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao conectar com a api",
                        message2: '',
                    });
                }).finally(() => setLoading(false));
        }
    }

    useEffect(() => {
        listarVendas();
    }, [])
    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000)
        }
    }, [alert])
    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={2} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3">
                            <div className="m-0 row justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">VENDAS</h6>
                                <div className="row">                                 
                                    <small className="font-weight-bold">VENDAS PENDENTES PARA EVENTOS: </small>
                                    <h6 className="font-weight-bold text-primary px-1">{vendas.filter((el:any) => (el.prioridade === 'Para evento' && el.status_venda !== 'Entregue')).length}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Table
                                    object={
                                        {
                                            id_venda: { name: 'venda', width: 'auto' },
                                            nome_cliente: { name: 'cliente', width: 'auto' },
                                            cod_cliente: { name: 'cod. cliente', width: 'auto' },
                                            data: { name: 'data / hora', width: 'auto' },
                                            data_entrega: { name: 'Entrega estimada', width: 'auto' },
                                            valor_pago: { name: 'pago', width: 'auto' },
                                            total_venda: { name: 'total', width: 'auto' },
                                            prioridade: { name: 'prioridade', width: 'auto' },
                                            status: { name: 'Pagamento', width: 'auto' },
                                            status_venda: { name: 'Status', width: 'auto' },
                                            nome_usuario: { name: 'designer', width: 'auto' }
                                        }
                                    }
                                    action={{
                                        excluir: remover,
                                        editar: "/cadastrarVendas/",
                                        adicionar: "/cadastrarVendas"
                                    }}
                                    id={"id_venda"}
                                    data={vendas}
                                    filterColumns={['nome_cliente', 'nome_usuario', 'data', 'data_entrega', 'status', 'cod_cliente', 'prioridade']} />
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default Vendas;