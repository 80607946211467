export default class ProdutoVO {
    id_produto: number;
    descricao_produto: string;
    constructor(
        id_produto: number,
        descricao_produto: string
    ) {
        this.id_produto = id_produto;
        this.descricao_produto = descricao_produto;
    }
}