export default class ClienteVO {
    id_cliente: number;
    cod_cliente: string;
    nome_cliente: string;
    cidade: string;
    uf: string;
    cep: string;
    rua: string;
    numero: number;
    complemento: string;
    bairro: string;
    telefone: string;
    cpf: string;
    email_cliente: string;
    constructor(
        id_cliente: number,
        cod_cliente: string,
        nome_cliente: string,
        cidade: string,
        uf: string,
        cep: string,
        numero: number,
        rua: string,
        bairro: string,
        telefone: string,
        email_cliente: string,
        complemento: string,
        cpf: string) {
        this.id_cliente = id_cliente;
        this.nome_cliente = nome_cliente;
        this.cidade = cidade;
        this.uf = uf;
        this.cep = cep;
        this.rua = rua;
        this.numero = numero;
        this.bairro = bairro;
        this.telefone = telefone;
        this.cpf = cpf;
        this.email_cliente = email_cliente;
        this.complemento = complemento;
        this.cod_cliente = cod_cliente;
    }
}