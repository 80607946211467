import React, { useEffect, useState } from 'react';
import './Gastos.css'; // Assuming you have a CSS file for styling
import api from '../api'; // Import your API functions
import AlertMessage from '../Components/AlertMessage'; // Import your AlertMessage component
import Loading from '../Components/Loading'; // Import your Loading component
import Table from '../Components/Table'; // Import your Table component
import GastoVO from '../VO/GastosVO';
import SideBar from '../Components/SideBar';

interface Props {
    location: any;
}

const Gastos: React.FC<Props> = (props) => {
    const [todosGastos, setTodosGastos] = useState<Array<GastoVO>>([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(
        props.location.state && props.location.state.alert
            ? props.location.state.alert
            : {
                  show: false,
                  type: '',
                  title: '',
                  mainMessage: '',
                  message2: '',
              }
    );

    async function listarGastos() {
        setLoading(true);
        try {
            const response = await api.get('/gastosDAO.php?listarTodos=listarTodos');
            if (response.status === 200) {
                if (response.data) {
                    if (!response.data.errorInfo) {
                        setTodosGastos(response.data);
                    } else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: 'Falha ao carregar os dados',
                            message2: '',
                        });
                    }
                } else {
                    setAlert({
                        show: true,
                        type: 'warning',
                        title: '',
                        mainMessage: 'Nenhum dado encontrado',
                        message2: response.data.errorInfo,
                    });
                }
            } else {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: 'Falha ao carregar os dados',
                    message2: '',
                });
            }
        } catch (err) {
            setAlert({
                show: true,
                type: 'danger',
                title: 'Erro',
                mainMessage: 'Falha ao conectar com a API',
                message2: '',
            });
        } finally {
            setLoading(false);
        }
    }

    async function remover(id: number) {
        if (window.confirm('Deseja remover o gasto do sistema?')) {
            setLoading(true);
            try {
                const response = await api.get('/gastosDAO.php?remover=' + id);
                if (response.status === 200) {
                    if (!response.data) {
                        setAlert({
                            show: true,
                            type: 'success',
                            title: '',
                            mainMessage: 'Gasto removido com sucesso',
                            message2: '',
                        });
                        listarGastos();
                    } else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: 'Não é possível remover ou alterar a linha',
                            message2: 'Existem dados que dependem dessa linha',
                        });
                    }
                } else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: 'Falha ao remover os dados',
                        message2: '',
                    });
                }
            } catch (err) {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: 'Falha ao conectar com a API',
                    message2: '',
                });
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        listarGastos();
    }, []);

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000);
        }
    }, [alert]);

    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={8} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">GASTOS</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Table
                                    object={{
                                        id_gasto: { name: 'cod', width: 'auto' },
                                        descricao_gasto: { name: 'descricao', width: 'auto' },
                                        total_gasto: { name: 'total', width: 'auto' },
                                        data_gasto: { name: 'data', width: 'auto' },
                                        tipo_gasto: { name: 'tipo', width: 'auto' },
                                    }}
                                    action={{
                                        excluir: remover,
                                        editar: '/cadastrarGastos/', // Provide your edit route
                                        adicionar: '/cadastrarGastos/', // Provide your add route
                                    }}
                                    id="id_gasto"
                                    data={todosGastos}
                                    filterColumns={['descricao_gasto', 'data_gasto', 'tipo_gasto']}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Gastos;
