import React, { ChangeEvent, useState, useEffect, FormEvent, FocusEvent } from 'react';
import './Clientes.css';
import SideBar from '../Components/SideBar';
import { FaArrowLeft } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import { Link, useHistory, useParams } from 'react-router-dom';
import ClienteVO from '../VO/ClienteVO';
import api from '../api';
import AlertMessage from '../Components/AlertMessage';
import Loading from '../Components/Loading';

interface Props {
}

interface ParamTypes {
    id_cliente: string
}


const CadastrarClientes: React.FC<Props> = (props) => {
    const [cliente, setCliente] = useState(new ClienteVO(0, '', '', '', '', '', 0, '', '', '', '', '', ''));
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({
        show: false,
        type: '',
        title: '',
        mainMessage: '',
        message2: '',
    });
    const { id_cliente } = useParams<ParamTypes>();
    const history = useHistory();

    async function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        var form = new FormData();
        let aux = JSON.parse(JSON.stringify(cliente));
        form.append("inserirOuAtualizar", "inserirOuAtualizar");
        Object.keys(aux).forEach((item) => {
            form.append(item, aux[item] ?? '');
        });
        await api.post('/ClienteDAO.php', form)
            .then(res => {
                if (res.status === 200) {
                    if (res.data?.errorInfo) {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao " + (id_cliente ? "atualizar" : "cadastrar") + " cliente",
                            message2: res.data.errorInfo,
                        });
                    }
                    else {
                        history.push("/clientes", {
                            alert: {
                                show: true,
                                type: 'success',
                                title: '',
                                mainMessage: "Cliente " + (id_cliente ? "atualizado" : "cadastrado") + " com sucesso",
                                message2: '',
                            }
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao " + (id_cliente ? "atualizar" : "cadastrar") + " cliente",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getById() {
        await api.get('/ClienteDAO.php?getById=' + id_cliente)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setCliente({ ...cliente, ...res.data });
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'danger',
                            title: 'Erro',
                            mainMessage: "Falha ao carregar cliente",
                            message2: '',
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar cliente",
                        message2: '',
                    });
                }

            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function handlerInput(event: ChangeEvent<HTMLInputElement>) {
        let aux = JSON.parse(JSON.stringify(cliente));
        if (event.target.name === 'cpf') {
            let value = event.target.value.replace(/\D/g, '');
            if (value.length <= 14) {
                if (value.length === 11) {
                    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
                } else if (value.length === 14) {
                    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, '$1.$2.$3/$4-$5');
                }
                aux[event.target.name] = value;
            }
        }
        else {
            aux[event.target.name] = event.target.value;
        }
        setCliente(aux);
    }

    async function handlerValidationInput(event: FocusEvent<HTMLInputElement>) {
        event.target.className = 'form-control ' + (event.target.validationMessage !== '' ? 'is-invalid' : 'is-valid')
    }

    async function pesquisarCep() {
        if (cliente.cep.length === 8) {
            await fetch(`https://viacep.com.br/ws/${cliente.cep}/json/`)
                .then(response => {
                    response.json()
                        .then(data => {
                            if (!data.erro) {
                                setCliente({
                                    ...cliente,
                                    cidade: data.localidade,
                                    uf: data.uf,
                                    bairro: data.bairro,
                                    rua: data.logradouro,

                                });
                                if (data.logradouro != '') {
                                    //aux['rua'] = data.logradouro + ", " + data.bairro;
                                }
                            }
                            else {
                                setCliente({
                                    ...cliente,
                                    cidade: '',
                                    uf: '',
                                    bairro: '',
                                    rua: '',

                                });
                            }
                        })
                }, err => console.log('erro'))
        }
    }

    useEffect(() => {
        if (id_cliente) {
            getById();
        }
        else {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        if (alert.show) {
            setTimeout(() => {
                setAlert({ ...alert, show: false });
            }, 5000)
        }
    }, [alert])

    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={6} />
            <main id="main">
                <div className="container-fluid">
                    <div className="card shadow mt-5">
                        <div className="card-header py-3 ">
                            <div className="card-head-cadastrar">
                                <Link to={"/clientes"} className='btn btn-primary mr-3'>
                                    <span className='icon text-white'>
                                        <FaArrowLeft />
                                    </span>
                                </Link>
                                <h6 className="m-0 font-weight-bold text-primary">{id_cliente ? "ATUALIZAR" : "CADASTRAR"} CLIENTES</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit}>
                                <div className="form-row">
                                    <div className="form-group col-md-7 position-relative">
                                        <label htmlFor="nome_cliente">Nome completo</label>
                                        <input
                                            autoComplete="nope"
                                            type="text"
                                            className="form-control"
                                            onChange={handlerInput}
                                            onBlur={handlerValidationInput}
                                            id="nome_cliente"
                                            name="nome_cliente"
                                            required
                                            value={cliente.nome_cliente}
                                            placeholder="Nome completo" />
                                        <div className="invalid-feedback">
                                            Obrigatório
                                        </div>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="cod_cliente">Código</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cod_cliente"
                                            value={cliente.cod_cliente}
                                            id="cod_cliente"
                                            required
                                            onBlur={handlerValidationInput}
                                            onChange={handlerInput}
                                            placeholder="0000" />
                                        <div className="invalid-feedback">
                                            Obrigatório
                                        </div>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="cpf">CPF/CNPJ</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="cpf"
                                            value={cliente.cpf}
                                            id="cpf"
                                            onChange={handlerInput}
                                            placeholder="CPF/CNPJ" />
                                        <div className="invalid-feedback">
                                            CPF inválido
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email_cliente">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email_cliente"
                                            value={cliente.email_cliente}
                                            id="email_cliente"
                                            onChange={handlerInput}
                                            placeholder="Email" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="telefone">Telefone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="telefone"
                                            value={cliente.telefone}
                                            id="telefone"
                                            onChange={handlerInput}
                                            placeholder="00 000000000" />
                                    </div>

                                    <div className="form-group col-md-5">
                                        <label htmlFor="rua">Rua</label>
                                        <input
                                            type="text"
                                            name="rua"
                                            value={cliente.rua}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="rua"
                                            placeholder="Rua" />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="numero">Nº</label>
                                        <input
                                            type="text"
                                            name="numero"
                                            value={cliente.numero}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="numero"
                                            placeholder="Nº" />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="complemento">Comp.</label>
                                        <input
                                            type="text"
                                            name="complemento"
                                            value={cliente.complemento}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="complemento"
                                            placeholder="Casa/Ap." />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="bairro">Bairro</label>
                                        <input
                                            type="text"
                                            name="bairro"
                                            value={cliente.bairro}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="bairro"
                                            placeholder="Bairro" />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputCity">Cidade</label>
                                        <input
                                            type="text"
                                            name="cidade"
                                            value={cliente.cidade}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="inputCity"
                                            placeholder="Cidade" />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label htmlFor="inputState">Estado</label>
                                        <input
                                            type="text"
                                            name="uf"
                                            value={cliente.uf}
                                            onChange={handlerInput}
                                            className="form-control"
                                            id="inputState"
                                            placeholder="UF" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="inputZip">CEP</label>
                                        <div className="form-row">
                                            <input
                                                type="text"
                                                name="cep"
                                                value={cliente.cep}
                                                onChange={handlerInput}
                                                maxLength={8}
                                                className="form-control col-md-9"
                                                id="inputZip" placeholder="CEP" />
                                            <button type='button' className="btn btn-primary col-md-3" onClick={pesquisarCep} title="Pesquisar por CEP">
                                                <BsSearch className='icon-btn' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        <button className="btn btn-primary float-right" type="submit">{id_cliente ? "Atualizar" : "Cadastrar"}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </main >
        </>
    )
}

export default CadastrarClientes;