import React from 'react';
import './styles/SideBar.css';
import { FaBootstrap, FaRegUser } from 'react-icons/fa';
import { AiOutlineDashboard, } from 'react-icons/ai';
import { VscGraphLine } from 'react-icons/vsc';
import { CgListTree } from 'react-icons/cg';
import { BsTable } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { TbReportMoney } from 'react-icons/tb';
import { GiFamilyTree } from 'react-icons/gi';
import { Link, useHistory } from 'react-router-dom';
import profile from '../assets/avatarDefault.png';
import UsuarioVO from '../VO/UsuarioVO'
import logo from '../assets/logo.png';

interface Props {
    active: number;
}

const SideBar: React.FC<Props> = (props) => {
    const history = useHistory();
    let user: UsuarioVO | null = null;
    let session = sessionStorage.getItem("login");
    user = (session ? JSON.parse(session) : null);
    function logOut() {
        sessionStorage.clear();
        history.push("/");
    }
    return (
        <aside id="sidebar">
            <div className='top-side'>
                <a href="" className="sidebar-top">
                    <img src={logo} className="icon-side-title" />
                    <span>Tadeu <small>Representações</small></span>
                </a>
                <hr />
                <ul className="nav nav-pills">
                    <li className="side-item">
                        <Link to={'/dashboard'} className={"side-link " + (props.active === 1 ? "active" : '')} aria-current="page">
                            <AiOutlineDashboard className="icon-side" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/vendas'} className={"side-link " + (props.active === 2 ? "active" : '')} aria-current="page">
                            <VscGraphLine className="icon-side" />
                            <span>Vendas</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={"/produtos"} className={"side-link " + (props.active === 3 ? "active" : '')} aria-current="page">
                            <BsTable className="icon-side" />
                            <span>Produtos</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/tiposVariacoes'} className={"side-link " + (props.active === 4 ? "active" : '')} aria-current="page">
                            <CgListTree className="icon-side" />
                            <span>Tipos de Variações</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/variacoes'} className={"side-link " + (props.active === 5 ? "active" : '')} aria-current="page">
                            <GiFamilyTree className="icon-side" />
                            <span>Variações</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/clientes'} className={"side-link " + (props.active === 6 ? "active" : '')} aria-current="page">
                            <FiUsers className="icon-side" />
                            <span>Clientes</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/usuarios'} className={"side-link " + (props.active === 7 ? "active" : '')} aria-current="page">
                            <FaRegUser className="icon-side" />
                            <span>Usuários</span>
                        </Link>
                    </li>
                    <li className="side-item">
                        <Link to={'/gastos'} className={"side-link " + (props.active === 8 ? "active" : '')} aria-current="page">
                            <TbReportMoney className="icon-side" />
                            <span>Gastos</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className=" dropdown-side">
                <a className="text-decoration-none dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={profile} alt="" width="32" height="32" className="rounded-circle me-2" />
                    <span>{(user?.nome_usuario)}</span>
                </a>
                <ul className="dropdown-menu shadow" aria-labelledby="dropdownUser1">
                    { /*<li><a className="dropdown-item" href="#">New project...</a></li>
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <hr />*/}
                    <li><a href='#' className="dropdown-item it"
                        onClick={logOut}>Sair</a></li>
                </ul>
            </div>
        </aside>
    )
}

export default SideBar;