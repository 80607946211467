import React, { useEffect, useState, useRef } from 'react';
import './Dashboard.css';
import SideBar from '../Components/SideBar';
import CardValores from './Components/CardValores';
import Grafico from './Components/Grafico';
import api from '../api';
import Loading from '../Components/Loading';
import AlertMessage from '../Components/AlertMessage';
import { converterMoeda } from '../Components/global';
import { AiFillPrinter } from 'react-icons/ai';
import VendaVO from '../VO/VendaVO';
import GastoVO from '../VO/GastosVO';

interface Props {
    location: any;
}

const Dashboard: React.FC<Props> = (props) => {
    const dataAtual = new Date();
    const [graficoAnual, setGraficoAnual] = useState(Array<any>);
    const [totalVendas, setTotalVendas] = useState(Object);
    const [totalGastos, setTotalGastos] = useState(0);
    const [totalProdutosItens, setTotalProdutosItens] = useState(Array<any>);
    const [totalVendasMes, setTotalVendasMes] = useState(Object);
    const [totalVendasAno, setTotalVendasAno] = useState(Object);
    const [mesRelatorio, setMesRelatorio] = useState(dataAtual.getFullYear() + '-' + String(dataAtual.getMonth() + 1).padStart(2, '0'));
    const [anoRelatorio, setAnoRelatorio] = useState(dataAtual.getFullYear().toString());
    const [semanaRelatorio, setSemanaRelatorio] = useState('0');
    const [tipoGastoRelatorio, setTipoGastoRelatorio] = useState('');
    const [totalVendasMila, setTotalVendasMila] = useState([0, 0, 0, 0, 0]);
    const [valorVendasMila, setValorVendasMila] = useState([0, 0, 0, 0, 0]);
    const [totalVendasJla, setTotalVendasJla] = useState([0, 0, 0, 0, 0]);
    const [valorVendasJla, setValorVendasJla] = useState([0, 0, 0, 0, 0]);
    const [totalVendasJr, setTotalVendasJr] = useState([0, 0, 0, 0, 0]);
    const [valorVendasJr, setValorVendasJr] = useState([0, 0, 0, 0, 0]);
    const [vendasEntregues, setVendasEntregues] = useState(Array<VendaVO>);
    const [gastos, setGastos] = useState(Array<GastoVO>);
    const tableToPrintRefQte = useRef<HTMLTableElement | null>(null);
    const tableToPrintRefValues = useRef<HTMLTableElement | null>(null);
    const tableToPrintRefTotalAnual = useRef<HTMLTableElement | null>(null);
    const tableToPrintRefQteAnual = useRef<HTMLTableElement | null>(null);
    const tableToPrintRefVendas = useRef<HTMLTableElement | null>(null);
    const tableToPrintRefGastos = useRef<HTMLTableElement | null>(null);
    const [loading, setLoading] = useState(true);
    const [relatorioAtual, setRelatorioAtual] = useState(5);
    const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'];
    const [alert, setAlert] = useState(props.location.state && props.location.state.alert ?
        props.location.state.alert :
        {
            show: false,
            type: '',
            title: '',
            mainMessage: '',
            message2: '',
        });
    async function getGraficoAnual() {
        setLoading(true);
        await api.get('/VendaDAO.php?graficoAnual=graficoAnual')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setGraficoAnual(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }
    async function getTotalVendas() {
        setLoading(true);
        await api.get('/VendaDAO.php?getTotalVendas=getTotalVendas')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTotalVendas(JSON.parse(res.data.vendas));
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getRelatorioAnual() {
        setLoading(true);
        await api.get('/VendaDAO.php?getRelatorioAnual=' + anoRelatorio)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            let result = JSON.parse(JSON.stringify(res.data));
                            setTotalVendasAno(result);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                console.log(err);
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getRelatorioMensal() {
        setLoading(true);
        await api.get('/VendaDAO.php?getRelatorioMensal=' + mesRelatorio + '-01')
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            let result = JSON.parse(JSON.stringify(res.data));
                            setTotalVendasMes(result);
                            var totalMila = [0, 0, 0, 0, 0];
                            var valorMila = [0, 0, 0, 0, 0];
                            result.mila.forEach((el: any) => {
                                totalMila = [
                                    totalMila[0] + parseInt(el.qte_semana_1),
                                    totalMila[1] + parseInt(el.qte_semana_2),
                                    totalMila[2] + parseInt(el.qte_semana_3),
                                    totalMila[3] + parseInt(el.qte_semana_4),
                                    totalMila[4] + parseInt(el.qte_semana_5),
                                ];
                                valorMila = [
                                    valorMila[0] + parseFloat(el.total_semana_1),
                                    valorMila[1] + parseFloat(el.total_semana_2),
                                    valorMila[2] + parseFloat(el.total_semana_3),
                                    valorMila[3] + parseFloat(el.total_semana_4),
                                    valorMila[4] + parseFloat(el.total_semana_5),
                                ]
                            });
                            setValorVendasMila(valorMila);
                            setTotalVendasMila(totalMila);

                            var totalJla = [0, 0, 0, 0, 0];
                            var valorJla = [0, 0, 0, 0, 0];
                            result.jla.forEach((el: any) => {
                                totalJla = [
                                    totalJla[0] + parseInt(el.qte_semana_1),
                                    totalJla[1] + parseInt(el.qte_semana_2),
                                    totalJla[2] + parseInt(el.qte_semana_3),
                                    totalJla[3] + parseInt(el.qte_semana_4),
                                    totalJla[4] + parseInt(el.qte_semana_5),
                                ];
                                valorJla = [
                                    valorJla[0] + parseFloat(el.total_semana_1),
                                    valorJla[1] + parseFloat(el.total_semana_2),
                                    valorJla[2] + parseFloat(el.total_semana_3),
                                    valorJla[3] + parseFloat(el.total_semana_4),
                                    valorJla[4] + parseFloat(el.total_semana_5),
                                ]
                            });
                            setTotalVendasJla(totalJla);
                            setValorVendasJla(valorJla);

                            var totalJr = [0, 0, 0, 0, 0];
                            var valorJr = [0, 0, 0, 0, 0];
                            result.jr.forEach((el: any) => {
                                totalJr = [
                                    totalJr[0] + parseInt(el.qte_semana_1),
                                    totalJr[1] + parseInt(el.qte_semana_2),
                                    totalJr[2] + parseInt(el.qte_semana_3),
                                    totalJr[3] + parseInt(el.qte_semana_4),
                                    totalJr[4] + parseInt(el.qte_semana_5),
                                ];
                                valorJr = [
                                    valorJr[0] + parseFloat(el.total_semana_1),
                                    valorJr[1] + parseFloat(el.total_semana_2),
                                    valorJr[2] + parseFloat(el.total_semana_3),
                                    valorJr[3] + parseFloat(el.total_semana_4),
                                    valorJr[4] + parseFloat(el.total_semana_5),
                                ]
                            });
                            setTotalVendasJr(totalJr);
                            setValorVendasJr(valorJr);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                console.log(err);
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getVendasEntregues() {
        setLoading(true);
        await api.get('/VendaDAO.php?getVendasEntregues=' + mesRelatorio + '-01' +
            (semanaRelatorio != '0' ? '&semana=' + semanaRelatorio : ''))
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setVendasEntregues(JSON.parse(JSON.stringify(res.data)));
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                console.log(err);
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getGastos() {
        setLoading(true);
        await api.get('/gastosDAO.php?getGastos=' + mesRelatorio + '-01' +
            (semanaRelatorio != '0' ? '&semana=' + semanaRelatorio : '') +
            (tipoGastoRelatorio != '' ? '&tipo_gasto=' + tipoGastoRelatorio : ''))
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setGastos(JSON.parse(JSON.stringify(res.data)));
                            let total = 0;
                            res.data.forEach((el:any) => {
                                total += parseFloat(el.total_gasto)
                            });
                            setTotalGastos(total);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                console.log(err);
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }

    async function getTotalProdutosItens() {
        setLoading(true);
        await api.get('/VendaDAO.php?getTotalProdutosItens=getTotalProdutosItens')
            .then(res => {

                if (res.status === 200) {
                    if (res.data) {
                        if (!res.data.errorInfo) {
                            setTotalProdutosItens(res.data);
                        }
                        else {
                            setAlert({
                                show: true,
                                type: 'danger',
                                title: 'Erro',
                                mainMessage: "Falha ao carregar os dados",
                                message2: '',
                            });
                        }
                    }
                    else {
                        setAlert({
                            show: true,
                            type: 'warning',
                            title: '',
                            mainMessage: "Nenhum dado encontrado",
                            message2: res.data.errorInfo,
                        });
                    }
                }
                else {
                    setAlert({
                        show: true,
                        type: 'danger',
                        title: 'Erro',
                        mainMessage: "Falha ao carregar os dados",
                        message2: '',
                    });
                }
            }).catch(err => {
                setAlert({
                    show: true,
                    type: 'danger',
                    title: 'Erro',
                    mainMessage: "Falha ao conectar com a api",
                    message2: '',
                });
            }).finally(() => setLoading(false));
    }
    const handlePrint = (ref: any, tipo: string) => {
        if (ref.current) {
            const printWindow = window.open('', '');
            printWindow!.document.open();
            printWindow!.document.write(`
            <html>
              <head>
                <title>Imprimir Resumo</title>
                <style>
                    body {
                    font-family: Arial, sans-serif;
                    box-sizing: border-box;
                    }

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        font-weight: bold;
                    }

                    th,
                    td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: center;
                    }

                    img {
                    max-width: 100px;
                    max-height: 100px;
                    }
                    .table-secondary {
                        background-color: #D6D8DB;
                    }
                    .table-dark {
                        background-color: #212529 ;
                        color: white;
                    }
                    .bg-dark {
                        background-color: #343A40 ;
                        color: white;
                    }
                    .bg-warning {
                        background-color: #FFC107 ;
                        color: black;
                    }
                    .bg-danger {
                        background-color: #DC3545;
                        color: white;
                    }
                    .text-danger {
                        color: #DC3545;
                    }                    
                    .table-xadrez {
                        color: white;
                    }
                    .table-xadrez tr:nth-child(even) td:nth-child(even) {
                        background-color: #DC3545; /* Cor para linhas pares */
                    }
                    .table-xadrez tr:nth-child(even) td:nth-child(odd) {
                        background-color: #343A40; /* Cor para linhas pares */
                    }
                    .table-xadrez tr:nth-child(odd) td:nth-child(even) {
                        background-color: #343A40; /* Cor para linhas pares */
                    }
                    .table-xadrez tr:nth-child(odd) td:nth-child(odd) {
                        background-color: #DC3545; /* Cor para linhas pares */
                    }
                </style>
              </head>
              <body>
                <h1>Relatório ${tipo}</h1>
                ${ref.current.outerHTML}
              </body>
            </html>
          `);
            printWindow!.document.close();
            setTimeout(() => {
                printWindow!.print();
                printWindow!.close();
            }, 500); // Aguarde 1 segundo antes de imprimir
        }
    };

    useEffect(() => {
        getTotalVendas();
        getGraficoAnual();
        getTotalProdutosItens();
    }, [])
    useEffect(() => {
        getRelatorioMensal();
    }, [mesRelatorio])
    useEffect(() => {
        getRelatorioAnual();
    }, [anoRelatorio])
    useEffect(() => {
        getVendasEntregues();
    }, [mesRelatorio, semanaRelatorio])
    useEffect(() => {
        getGastos();
    }, [mesRelatorio, semanaRelatorio, tipoGastoRelatorio])
    return (
        <>
            {loading ? <Loading /> : ''}
            <AlertMessage {...alert} />
            <SideBar active={1} />
            <main id="main">
                <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                    </div>
                    <div className="column">
                        <div className="row">
                            <CardValores titulo='VENDAS DIÁRIAS' ganhos={converterMoeda(totalVendas?.dia?.valor)} vendas={totalVendas?.dia?.qte} />
                            <CardValores titulo='VENDAS SEMANAIS' ganhos={converterMoeda(totalVendas?.semana?.valor)} vendas={totalVendas?.semana?.qte} />
                            <CardValores titulo='VENDAS MENSAIS' ganhos={converterMoeda(totalVendas?.mes?.valor)} vendas={totalVendas?.mes?.qte} />
                            <CardValores titulo='VENDAS ANUAIS' ganhos={converterMoeda(totalVendas?.ano?.valor)} vendas={totalVendas?.ano?.qte} />
                        </div>
                        <br /><br />
                        <div className="row container-relatorios">
                            <div className="form-group col-md-4">
                                <label htmlFor="relatorio">Relatórios</label>
                                <select
                                    id="relatorio"
                                    value={relatorioAtual}
                                    onChange={(e) => setRelatorioAtual(parseInt(e.target.value))}
                                    className="form-control">
                                    <option selected value={'5'}>Relatório anual de valores</option>
                                    <option value={'4'}>Relatório mensal de quantidades</option>
                                    <option value={'3'}>Relatório mensal de valores</option>
                                    <option value={'2'}>Pedidos entregues mês/semana</option>
                                    <option value={'1'}>Relatório de gastos</option>

                                </select>
                            </div>
                        </div>
                        <br /><br />
                        {relatorioAtual === 5 ? <div className="row container-relatorios">
                            <div className="col-12">
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Relatório anual de valores</h1>
                                </div>
                                <div className="d-sm-flex align-items-center justify-content-between mb-2">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="ano">Ano</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="ano"
                                            id="ano"
                                            value={anoRelatorio}
                                            onChange={(e) => setAnoRelatorio(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => handlePrint(tableToPrintRefTotalAnual, `anual de valores (${anoRelatorio})`)}
                                    >Imprimir
                                        <AiFillPrinter className="icon-btn ml-1" />
                                    </button>
                                </div>
                                <div className="table-resp">
                                    <table ref={tableToPrintRefTotalAnual} className="table table-bordered font-weight-bold">
                                        <thead className="table-dark table-xadrez">
                                            <tr>
                                                <th scope="col">PRODUTO</th>
                                                {meses.map((el) => {
                                                    return <th key={el} scope="col">{el.toUpperCase().substring(0, 3)}</th>
                                                })}
                                                <th scope="col">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-xadrez">
                                            {totalVendasAno.mila?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "mila"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_janeiro)}</td>
                                                        <td>{converterMoeda(el.total_fevereiro)}</td>
                                                        <td>{converterMoeda(el.total_marco)}</td>
                                                        <td>{converterMoeda(el.total_abril)}</td>
                                                        <td>{converterMoeda(el.total_maio)}</td>
                                                        <td>{converterMoeda(el.total_junho)}</td>
                                                        <td>{converterMoeda(el.total_julho)}</td>
                                                        <td>{converterMoeda(el.total_agosto)}</td>
                                                        <td>{converterMoeda(el.total_setembro)}</td>
                                                        <td>{converterMoeda(el.total_outubro)}</td>
                                                        <td>{converterMoeda(el.total_novembro)}</td>
                                                        <td>{converterMoeda(el.total_dezembro)}</td>
                                                        <td>{converterMoeda(el.total_anual)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasAno.jla?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jla"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_janeiro)}</td>
                                                        <td>{converterMoeda(el.total_fevereiro)}</td>
                                                        <td>{converterMoeda(el.total_marco)}</td>
                                                        <td>{converterMoeda(el.total_abril)}</td>
                                                        <td>{converterMoeda(el.total_maio)}</td>
                                                        <td>{converterMoeda(el.total_junho)}</td>
                                                        <td>{converterMoeda(el.total_julho)}</td>
                                                        <td>{converterMoeda(el.total_agosto)}</td>
                                                        <td>{converterMoeda(el.total_setembro)}</td>
                                                        <td>{converterMoeda(el.total_outubro)}</td>
                                                        <td>{converterMoeda(el.total_novembro)}</td>
                                                        <td>{converterMoeda(el.total_dezembro)}</td>
                                                        <td>{converterMoeda(el.total_anual)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasAno.jr?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jr"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_janeiro)}</td>
                                                        <td>{converterMoeda(el.total_fevereiro)}</td>
                                                        <td>{converterMoeda(el.total_marco)}</td>
                                                        <td>{converterMoeda(el.total_abril)}</td>
                                                        <td>{converterMoeda(el.total_maio)}</td>
                                                        <td>{converterMoeda(el.total_junho)}</td>
                                                        <td>{converterMoeda(el.total_julho)}</td>
                                                        <td>{converterMoeda(el.total_agosto)}</td>
                                                        <td>{converterMoeda(el.total_setembro)}</td>
                                                        <td>{converterMoeda(el.total_outubro)}</td>
                                                        <td>{converterMoeda(el.total_novembro)}</td>
                                                        <td>{converterMoeda(el.total_dezembro)}</td>
                                                        <td>{converterMoeda(el.total_anual)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasAno.outros?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "outros"}>
                                                        <td>{el.produto}</td>
                                                        <td>{converterMoeda(el.total_janeiro)}</td>
                                                        <td>{converterMoeda(el.total_fevereiro)}</td>
                                                        <td>{converterMoeda(el.total_marco)}</td>
                                                        <td>{converterMoeda(el.total_abril)}</td>
                                                        <td>{converterMoeda(el.total_maio)}</td>
                                                        <td>{converterMoeda(el.total_junho)}</td>
                                                        <td>{converterMoeda(el.total_julho)}</td>
                                                        <td>{converterMoeda(el.total_agosto)}</td>
                                                        <td>{converterMoeda(el.total_setembro)}</td>
                                                        <td>{converterMoeda(el.total_outubro)}</td>
                                                        <td>{converterMoeda(el.total_novembro)}</td>
                                                        <td>{converterMoeda(el.total_dezembro)}</td>
                                                        <td>{converterMoeda(el.total_anual)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasAno.mila?.length > 0 ||
                                                totalVendasAno.jla?.length > 0 ||
                                                totalVendasAno.jr?.length > 0 ||
                                                totalVendasAno.outros?.length > 0 ?
                                                <tr>
                                                    <td className="table-dark">TOTAL</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_janeiro)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_fevereiro)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_marco)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_abril)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_maio)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_junho)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_julho)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_agosto)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_setembro)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_outubro)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_novembro)}</td>
                                                    <td>{converterMoeda(totalVendasAno.total_mensal.total_dezembro)}</td>
                                                    <td className="table-dark border-resultado">{converterMoeda(totalVendasAno.total_geral)}</td>
                                                </tr>
                                                : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ''}
                        {relatorioAtual === 4 ? <div className="row container-relatorios">
                            <div className="col-12">
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Relatório de quantidades</h1>
                                </div>
                                <div className="d-sm-flex align-items-center justify-content-between mb-2">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Mês/ano</label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="data"
                                            id="data"
                                            value={mesRelatorio}
                                            onChange={(e) => setMesRelatorio(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => handlePrint(tableToPrintRefQte, "de quantidades")}
                                    >Imprimir
                                        <AiFillPrinter className="icon-btn ml-1" />
                                    </button>
                                </div>
                                <div className="table-resp">
                                    <table ref={tableToPrintRefQte} className="table table-bordered font-weight-bold">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">PRODUTO</th>
                                                <th scope="col">SEMANA 1</th>
                                                <th scope="col">SEMANA 2</th>
                                                <th scope="col">SEMANA 3</th>
                                                <th scope="col">SEMANA 4</th>
                                                <th scope="col">SEMANA 5</th>
                                                <th scope="col">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalVendasMes.mila?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "mila"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{el.qte_semana_1}</td>
                                                        <td>{el.qte_semana_2}</td>
                                                        <td>{el.qte_semana_3}</td>
                                                        <td>{el.qte_semana_4}</td>
                                                        <td>{el.qte_semana_5}</td>
                                                        <td>{parseInt(el.qte_semana_1) + parseInt(el.qte_semana_2) + parseInt(el.qte_semana_3) +
                                                            parseInt(el.qte_semana_4) + parseInt(el.qte_semana_5)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.mila?.length > 0 ? <tr className="text-white" key={"total_mila"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{totalVendasMila[0]}</td>
                                                <td className="bg-dark">{totalVendasMila[1]}</td>
                                                <td className="bg-danger">{totalVendasMila[2]}</td>
                                                <td className="bg-dark">{totalVendasMila[3]}</td>
                                                <td className="bg-danger">{totalVendasMila[4]}</td>
                                                <td className="bg-dark">{totalVendasMila[0] + totalVendasMila[1] + totalVendasMila[2] +
                                                    totalVendasMila[3] + totalVendasMila[4]}</td>
                                            </tr> : ''}
                                            {totalVendasMes.jla?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.jla?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jla"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{el.qte_semana_1}</td>
                                                        <td>{el.qte_semana_2}</td>
                                                        <td>{el.qte_semana_3}</td>
                                                        <td>{el.qte_semana_4}</td>
                                                        <td>{el.qte_semana_5}</td>
                                                        <td>{parseInt(el.qte_semana_1) + parseInt(el.qte_semana_2) + parseInt(el.qte_semana_3) +
                                                            parseInt(el.qte_semana_4) + parseInt(el.qte_semana_5)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.jla?.length > 0 ? <tr className="text-white" key={"total_jla"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{totalVendasJla[0]}</td>
                                                <td className="bg-dark">{totalVendasJla[1]}</td>
                                                <td className="bg-danger">{totalVendasJla[2]}</td>
                                                <td className="bg-dark">{totalVendasJla[3]}</td>
                                                <td className="bg-danger">{totalVendasJla[4]}</td>
                                                <td className="bg-dark">{totalVendasJla[0] + totalVendasJla[1] + totalVendasJla[2] +
                                                    totalVendasJla[3] + totalVendasJla[4]}</td>
                                            </tr> : ''}
                                            {totalVendasMes.jr?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.jr?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jr"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{el.qte_semana_1}</td>
                                                        <td>{el.qte_semana_2}</td>
                                                        <td>{el.qte_semana_3}</td>
                                                        <td>{el.qte_semana_4}</td>
                                                        <td>{el.qte_semana_5}</td>
                                                        <td>{parseInt(el.qte_semana_1) + parseInt(el.qte_semana_2) + parseInt(el.qte_semana_3) +
                                                            parseInt(el.qte_semana_4) + parseInt(el.qte_semana_5)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.jr?.length > 0 ? <tr className="text-white" key={"total_jla"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{totalVendasJr[0]}</td>
                                                <td className="bg-dark">{totalVendasJr[1]}</td>
                                                <td className="bg-danger">{totalVendasJr[2]}</td>
                                                <td className="bg-dark">{totalVendasJr[3]}</td>
                                                <td className="bg-danger">{totalVendasJr[4]}</td>
                                                <td className="bg-dark">{totalVendasJr[0] + totalVendasJr[1] + totalVendasJr[2] +
                                                    totalVendasJr[3] + totalVendasJr[4]}</td>
                                            </tr> : ''}
                                            {totalVendasMes.outros?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.outros?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "outros"}>
                                                        <td>{el.produto}</td>
                                                        <td>{el.qte_semana_1}</td>
                                                        <td>{el.qte_semana_2}</td>
                                                        <td>{el.qte_semana_3}</td>
                                                        <td>{el.qte_semana_4}</td>
                                                        <td>{el.qte_semana_5}</td>
                                                        <td>{parseInt(el.qte_semana_1) + parseInt(el.qte_semana_2) + parseInt(el.qte_semana_3) +
                                                            parseInt(el.qte_semana_4) + parseInt(el.qte_semana_5)}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ''}
                        {relatorioAtual === 3 ? <div className="row container-relatorios">
                            <div className="col-12">
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Relatório de valores</h1>
                                </div>
                                <div className="d-sm-flex align-items-center justify-content-between mb-2">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Mês/ano</label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="data"
                                            id="data"
                                            value={mesRelatorio}
                                            onChange={(e) => setMesRelatorio(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => handlePrint(tableToPrintRefValues, "de valores")}
                                    >Imprimir
                                        <AiFillPrinter className="icon-btn ml-1" />
                                    </button>
                                </div>
                                <div className="table-resp">
                                    <table ref={tableToPrintRefValues} className="table table-bordered font-weight-bold">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">PRODUTO</th>
                                                <th scope="col">SEMANA 1</th>
                                                <th scope="col">SEMANA 2</th>
                                                <th scope="col">SEMANA 3</th>
                                                <th scope="col">SEMANA 4</th>
                                                <th scope="col">SEMANA 5</th>
                                                <th scope="col">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalVendasMes.mila?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "mila"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_semana_1)}</td>
                                                        <td>{converterMoeda(el.total_semana_2)}</td>
                                                        <td>{converterMoeda(el.total_semana_3)}</td>
                                                        <td>{converterMoeda(el.total_semana_4)}</td>
                                                        <td>{converterMoeda(el.total_semana_5)}</td>
                                                        <td>{converterMoeda(parseFloat(el.total_semana_1) + parseFloat(el.total_semana_2) + parseFloat(el.total_semana_3) +
                                                            parseFloat(el.total_semana_4) + parseFloat(el.total_semana_5))}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.mila?.length > 0 ? <tr className="text-white" key={"total_mila"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasMila[0])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasMila[1])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasMila[2])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasMila[3])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasMila[4])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasMila[0] + valorVendasMila[1] + valorVendasMila[2] +
                                                    valorVendasMila[3] + valorVendasMila[4])}</td>
                                            </tr> : ''}
                                            {totalVendasMes.jla?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.jla?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jla"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_semana_1)}</td>
                                                        <td>{converterMoeda(el.total_semana_2)}</td>
                                                        <td>{converterMoeda(el.total_semana_3)}</td>
                                                        <td>{converterMoeda(el.total_semana_4)}</td>
                                                        <td>{converterMoeda(el.total_semana_5)}</td>
                                                        <td>{converterMoeda(parseFloat(el.total_semana_1) + parseFloat(el.total_semana_2) + parseFloat(el.total_semana_3) +
                                                            parseFloat(el.total_semana_4) + parseFloat(el.total_semana_5))}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.jla?.length > 0 ? <tr className="text-white" key={"total_jla"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJla[0])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJla[1])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJla[2])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJla[3])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJla[4])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJla[0] + valorVendasJla[1] + valorVendasJla[2] +
                                                    valorVendasJla[3] + valorVendasJla[4])}</td>
                                            </tr> : ''}
                                            {totalVendasMes.jr?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.jr?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "jr"}>
                                                        <td>{el.produto + ": " + el.variacao}</td>
                                                        <td>{converterMoeda(el.total_semana_1)}</td>
                                                        <td>{converterMoeda(el.total_semana_2)}</td>
                                                        <td>{converterMoeda(el.total_semana_3)}</td>
                                                        <td>{converterMoeda(el.total_semana_4)}</td>
                                                        <td>{converterMoeda(el.total_semana_5)}</td>
                                                        <td>{converterMoeda(parseFloat(el.total_semana_1) + parseFloat(el.total_semana_2) + parseFloat(el.total_semana_3) +
                                                            parseFloat(el.total_semana_4) + parseFloat(el.total_semana_5))}</td>
                                                    </tr>
                                                )
                                            })}
                                            {totalVendasMes.jr?.length > 0 ? <tr className="text-white" key={"total_jr"}>
                                                <td className="text-danger text-right">TOTAL</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJr[0])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJr[1])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJr[2])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJr[3])}</td>
                                                <td className="bg-danger">{converterMoeda(valorVendasJr[4])}</td>
                                                <td className="bg-dark">{converterMoeda(valorVendasJr[0] + valorVendasJr[1] + valorVendasJr[2] +
                                                    valorVendasJr[3] + valorVendasJr[4])}</td>
                                            </tr> : ''}
                                            {totalVendasMes.outros?.length > 0 ? <tr><td className="table-dark" colSpan={7}></td></tr> : ''}
                                            {totalVendasMes.outros?.map((el: any, i: any) => {
                                                return (
                                                    <tr key={i + "outros"}>
                                                        <td>{el.produto}</td>
                                                        <td>{converterMoeda(el.total_semana_1)}</td>
                                                        <td>{converterMoeda(el.total_semana_2)}</td>
                                                        <td>{converterMoeda(el.total_semana_3)}</td>
                                                        <td>{converterMoeda(el.total_semana_4)}</td>
                                                        <td>{converterMoeda(el.total_semana_5)}</td>
                                                        <td>{converterMoeda(parseFloat(el.total_semana_1) + parseFloat(el.total_semana_2) + parseFloat(el.total_semana_3) +
                                                            parseFloat(el.total_semana_4) + parseFloat(el.total_semana_5))}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ''}
                        {relatorioAtual === 2 ? <div className="row container-relatorios">
                            <div className="col-12">
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Pedidos entregues</h1>
                                </div>
                                <div className="d-sm-flex align-items-center justify-content-between mb-2 form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Mês/ano</label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="data"
                                            id="data"
                                            value={mesRelatorio}
                                            onChange={(e) => setMesRelatorio(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Semana</label>
                                        <select
                                            name="semana"
                                            value={semanaRelatorio}
                                            onChange={(e) => setSemanaRelatorio(e.target.value)}
                                            className="form-control">
                                            <option value={'0'}>Selecione uma semana</option>
                                            <option value={'1'}>Semana 1</option>
                                            <option value={'2'}>Semana 2</option>
                                            <option value={'3'}>Semana 3</option>
                                            <option value={'4'}>Semana 4</option>
                                            <option value={'5'}>Semana 5</option>

                                        </select>
                                    </div>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => handlePrint(tableToPrintRefVendas, "de pedidos entregues")}
                                    >Imprimir
                                        <AiFillPrinter className="icon-btn ml-1" />
                                    </button>
                                </div>
                                <div className="table-resp">
                                    <table ref={tableToPrintRefVendas} className="table table-bordered font-weight-bold">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">VENDA</th>
                                                <th scope="col">CLIENTE</th>
                                                <th scope="col">COD. CLIENTE</th>
                                                <th scope="col">DATA</th>
                                                <th scope="col">ENTREGA ESTIMADA</th>
                                                <th scope="col">VALOR PAGO</th>
                                                <th scope="col">TOTAL</th>
                                                <th scope="col">DESIGNER</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vendasEntregues?.map((el) => {
                                                return (
                                                    <tr key={el.id_venda}>
                                                        <td>{el.id_venda}</td>
                                                        <td>{el.nome_cliente}</td>
                                                        <td>{el.cod_cliente}</td>
                                                        <td>{new Date(el.data?.replace('T', ' ')).toLocaleString('pt-BR')}</td>
                                                        <td>{new Date(el.data_entrega?.replace('T', ' ')).toLocaleString('pt-BR')}</td>
                                                        <td>{converterMoeda(el.valor_pago)}</td>
                                                        <td>{converterMoeda(el.total_venda)}</td>
                                                        <td>{el.nome_usuario}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ''}
                        {relatorioAtual === 1 ? <div className="row container-relatorios">
                            <div className="col-12">
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Relatório de gastos</h1>
                                </div>
                                <div className="d-sm-flex align-items-center justify-content-between mb-2 form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Mês/ano</label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            name="data"
                                            id="data"
                                            value={mesRelatorio}
                                            onChange={(e) => setMesRelatorio(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Semana</label>
                                        <select
                                            name="semana"
                                            value={semanaRelatorio}
                                            onChange={(e) => setSemanaRelatorio(e.target.value)}
                                            className="form-control">
                                            <option value={'0'}>Selecione uma semana</option>
                                            <option value={'1'}>Semana 1</option>
                                            <option value={'2'}>Semana 2</option>
                                            <option value={'3'}>Semana 3</option>
                                            <option value={'4'}>Semana 4</option>
                                            <option value={'5'}>Semana 5</option>

                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="data">Tipo Gasto</label>
                                        <select
                                            name="tipo_gasto"
                                            value={tipoGastoRelatorio}
                                            onChange={(e) => setTipoGastoRelatorio(e.target.value)}
                                            className="form-control">
                                            <option value={''}>Selecione um tipo de gasto</option>
                                            <option value={'empresarial'}>Empresarial</option>
                                            <option value={'mila'}>MILÃ</option>
                                            <option value={'jr'}>JR FARDAMENTOS</option>
                                            <option value={'jla'}>JLA</option>
                                            <option value={'bones_ze'}>BONÉS ( ZÉ )</option>
                                            <option value={'digitalize_beto'}>DIGITALIZE ( BETO )</option>
                                            <option value={'grafica_rapida'}>( GRÁFICA RAPIDA )</option>
                                            <option value={'pd_ricardo'}>PROJETO DE DEUS ( RICARDO )</option>
                                            <option value={'grafica_didiu'}>FIXAR GRÁFICA ( DIDIU )</option>
                                            <option value={'copo_brinde'}>COPO ( Monyque Santana Encaixe Brinde )</option>
                                        </select>
                                    </div>
                                    <button
                                        className="btn btn-primary m-1"
                                        onClick={() => handlePrint(
                                            tableToPrintRefGastos,
                                            "de gastos - " +
                                            (meses[parseInt(mesRelatorio.split('-')[1]) - 1] + '/' + mesRelatorio.split('-')[0]) +
                                            (semanaRelatorio !== '0' ? " - Semana " + semanaRelatorio : '') +
                                            (tipoGastoRelatorio !== '' ? " - " + tipoGastoRelatorio.toUpperCase() : '')
                                        )}
                                    >Imprimir
                                        <AiFillPrinter className="icon-btn ml-1" />
                                    </button>
                                </div>
                                <div className="table-resp">
                                    <table ref={tableToPrintRefGastos} className="table table-bordered font-weight-bold">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">COD</th>
                                                <th scope="col">DESCRIÇÃO</th>
                                                <th scope="col">DATA</th>
                                                <th scope="col">TIPO</th>
                                                <th scope="col">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gastos?.map((el) => {
                                                return (
                                                    <tr key={el.id_gasto}>
                                                        <td>{el.id_gasto}</td>
                                                        <td>{el.descricao_gasto}</td>
                                                        <td>{el.data_gasto}</td>
                                                        <td>{el.tipo_gasto.toUpperCase()}</td>
                                                        <td>{converterMoeda(el.total_gasto)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td className="bg-warning">TOTAL</td>
                                                <td className="bg-warning">{converterMoeda(totalGastos)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ''}
                        <br /><br />
                        {/*<br /><hr /><br />
                        <div className="row">
                            <div className="col-12">
                                <Grafico
                                    tipo='line'
                                    titulo="GANHOS ANUAL"
                                    labels={meses}
                                    data={graficoAnual.map((el) => { return (el.total_mes) })}
                                    id="ganhosanual" />
                            </div>
                        </div>
                        <br /><br />
                        <div className="row">
                            <div className="col-6">
                                <Grafico
                                    tipo='bar'
                                    titulo="GANHOS POR PRODUTO"
                                    labels={totalProdutosItens?.map((pi) => {
                                        return (pi.descricao)
                                    })}
                                    data={totalProdutosItens?.map((pi) => {
                                        return (pi.total)
                                    })}
                                    id="graficoProdutosGanhos" />
                            </div>
                            <div className="col-6">
                                <Grafico
                                    tipo='bar'
                                    titulo="QUANTIDADE DE VENDAS"
                                    labels={totalProdutosItens?.map((pi) => {
                                        return (pi.descricao)
                                    })}
                                    data={totalProdutosItens?.map((pi) => {
                                        return (pi.quantitativo)
                                    })}
                                    id="graficoProdutosQte" />
                            </div>
                        </div>*/}
                    </div>

                </div>
            </main>
        </>
    )
}

export default Dashboard;