export default class GastoVO {
    id_gasto: number;
    descricao_gasto: string;
    total_gasto: number;
    data_gasto: string;
    tipo_gasto: string;
    constructor(
        id_gasto: number,
        descricao_gasto: string,
        total_gasto: number,
        data_gasto: string,
        tipo_gasto: string
    ) {
        this.id_gasto = id_gasto;
        this.descricao_gasto = descricao_gasto;
        this.total_gasto = total_gasto;
        this.data_gasto = data_gasto;
        this.tipo_gasto = tipo_gasto;
    }
}
