import VariacaoVO from "./VariacaoVO";

export default class TipoVariacaoVO {
    id_tipo_variacao: number;
    fk_produto: number;
    variacao_selecionada: VariacaoVO | null;
    descricao_tipo_variacao: string;
    variacoes: Array<VariacaoVO>;
    descricao_produto: string;
    constructor(
        id_tipo_variacao: number,
        fk_produto: number,
        variacao_selecionada: VariacaoVO | null,
        descricao_tipo_variacao: string,
        variacoes: Array<VariacaoVO>,
        descricao_produto: string
    ) {
        this.id_tipo_variacao = id_tipo_variacao;
        this.fk_produto = fk_produto;
        this.descricao_tipo_variacao = descricao_tipo_variacao;
        this.variacoes = variacoes;
        this.variacao_selecionada = variacao_selecionada;
        this.descricao_produto = descricao_produto;
    }
}